import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import App from './components/app/App';
import BlueApp from './apps/blue/src/components/app/App';
import PinkApp from './apps/pink/src/components/app/App';
import * as serviceWorker from './serviceWorker';
import * as Config from "./utils/config";

import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

import { BrowserRouter } from "react-router-dom";

import appointmentServicesReducer from './components/reducers/appointment-services-reducer';
import customerReducer from './components/reducers/customer-reducer';
import merchantReducer from './components/reducers/merchant-reducer';
import pageReducer from './components/reducers/page-reducer';
import availableSpecialistsReducer from "./components/reducers/available-specialists-reducer";
import pageLoaderReducer from "./components/reducers/page-loader-reducer";


//blue src
import blueAppointmentReducer from './apps/blue/src/components/reducers/appointment-reducer';
import blueAppointmentDateReducer from './apps/blue/src/components/reducers/appointment-date-reducer';
import blueCustomerAppointmentsReducer from './apps/blue/src/components/reducers/customer-appointments-reducer';
import blueMerchantReducer from './apps/blue/src/components/reducers/merchant-reducer';
import bluePageReducer from './apps/blue/src/components/reducers/page-reducer';
import blueAppointmentServicesReducer from './apps/blue/src/components/reducers/appointment-services-reducer';
import blueCustomerReducer from './apps/blue/src/components/reducers/customer-reducer';


//pink src
import pinkMerchantReducer from './apps/pink/src/components/reducers/merchant-reducer';
import pinkPageReducer from './apps/pink/src/components/reducers/page-reducer';
import pinkAppointmentReducer from './apps/pink/src/components/reducers/appointment-reducer';
import pinkAppointmentDateReducer from './apps/pink/src/components/reducers/appointment-date-reducer';
import pinkAppointmentServicesReducer from './apps/pink/src/components/reducers/appointment-services-reducer';
import pinkCustomerReducer from './apps/pink/src/components/reducers/customer-reducer';
import pinkCustomerAppointmentsReducer from './apps/pink/src/components/reducers/customer-appointments-reducer';

/**
* Redux Combine All Enhancers (For middleware, etc..)
**/
const allStoreEnhancers = compose(
  applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


const path = window.location.pathname;
const id = path.split('/').filter(q => q)[0];
axios.get(`${Config.API_URL}/merchants/${id}`, {
  headers: {
    'Authorization': Config.TOKEN,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})
  .then((response) => {
    const merchant = response.data;

    let theme = merchant.internalMessage.merchant_business.customer_website_type;

    if(theme === 2) {

      import('react-calendar/dist/Calendar.css');
      import('bootstrap/dist/css/bootstrap.css');
      import('./assets/css/index.css');
      import('./assets/css/app.min.css');
      /**
       * Redux Combine All Reducers
       **/
      const allReducers = combineReducers({
        page: pageReducer,
        merchant: merchantReducer,
        customerInformation: customerReducer,
        appointmentServices: appointmentServicesReducer,
        availableSpecialists: availableSpecialistsReducer,
        loader: pageLoaderReducer
      });

      

      const initState = {
        merchant: null,
        page: 'appointment',
        appointmentServices: [{
            service: [],
            specialist: [],
            date: null,
            time: null,
            count: 1
        }],
        customerInformation: {
            name: null,
            birthDate: null,
            gender: null,
            email: null,
            mobileNumber: null,
            notes: null,
        },
        loader: {
          pageLoading: true,
          componentLoading: true
        }
      }

      /**
      * Redux Store
      **/
      const store = createStore(
        allReducers,
        initState,
        allStoreEnhancers
      );

      const root = ReactDOM.createRoot(document.getElementById('root'));

       
      setTimeout(() => {
        root.render(
          <React.StrictMode>
            <Provider store={store}> 
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
          </React.StrictMode>
        );  
      }, 200);

    } else if(theme === 1) {
      import('./apps/blue/src/assets/css/app.min.css');
      /**
       * Redux Combine All Reducers
       **/
      const allReducers = combineReducers({
          merchant: blueMerchantReducer,
          page: bluePageReducer,
          appointmentDetails: blueAppointmentReducer,
          appointmentDate: blueAppointmentDateReducer,
          appointmentServices: blueAppointmentServicesReducer,
          customerInformation: blueCustomerReducer,
          customerAppointments: blueCustomerAppointmentsReducer
      });

      const initState = {
          page: 'form',
          appointmentServices: [{
              service: [],
              specialist: [],
              time: []
          }],
          customerInformation: {
              name: null,
              mobile: null,
              email: null,
              birth_date: null
          }
      }

      /**
      * Redux Store
      **/
      const store = createStore(
          allReducers,
          initState,
          allStoreEnhancers
      );

      const root = ReactDOM.createRoot(document.getElementById('root'));

      setTimeout(() => {
        root.render(
          <React.StrictMode>
            <Provider store={store}> 
                <BrowserRouter>
                    <BlueApp />
                </BrowserRouter>
            </Provider>
          </React.StrictMode>
        ); 
      }, 200);

    } else {

      import('./apps/pink/src/assets/css/app.min.css');
      /**
       * Redux Combine All Reducers
       **/
      const allReducers = combineReducers({
          merchant: pinkMerchantReducer,
          page: pinkPageReducer,
          appointmentDetails: pinkAppointmentReducer,
          appointmentDate: pinkAppointmentDateReducer,
          appointmentServices: pinkAppointmentServicesReducer,
          customerInformation: pinkCustomerReducer,
          customerAppointments: pinkCustomerAppointmentsReducer
      });

      const initState = {
          page: 'form',
          appointmentServices: [{
              service: [],
              specialist: [],
              time: []
          }],
          customerInformation: {
              name: null,
              mobile: null,
              email: null,
              birth_date: null
          }
      }

      /**
      * Redux Store
      **/
      const store = createStore(
          allReducers,
          initState,
          allStoreEnhancers
      );

      const root = ReactDOM.createRoot(document.getElementById('root'));
      setTimeout(() => {
        root.render(
          <React.StrictMode>
            <Provider store={store}> 
                <BrowserRouter>
                    <PinkApp />
                </BrowserRouter>
            </Provider>
          </React.StrictMode>
        ); 
      }, 200);


    }
  })
  .catch((error) => {
    console.log(error);
    import('./apps/blue/src/assets/css/app.min.css');
      /**
       * Redux Combine All Reducers
       **/
      const allReducers = combineReducers({
          merchant: blueMerchantReducer,
          page: bluePageReducer,
          appointmentDetails: blueAppointmentReducer,
          appointmentDate: blueAppointmentDateReducer,
          appointmentServices: blueAppointmentServicesReducer,
          customerInformation: blueCustomerReducer,
          customerAppointments: blueCustomerAppointmentsReducer
      });

      const initState = {
          page: 'form',
          appointmentServices: [{
              service: [],
              specialist: [],
              time: []
          }],
          customerInformation: {
              name: null,
              mobile: null,
              email: null,
              birth_date: null
          }
      }

      /**
      * Redux Store
      **/
      const store = createStore(
          allReducers,
          initState,
          allStoreEnhancers
      );

      const root = ReactDOM.createRoot(document.getElementById('root'));
      setTimeout(() => {
        root.render(
          <React.StrictMode>
            <Provider store={store}> 
                <BrowserRouter>
                    <BlueApp />
                </BrowserRouter>
            </Provider>
          </React.StrictMode>
        ); 
      }, 200); 
  })



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
