import { GET_AVAILABLE_SPECIALISTS, UPDATE_SPECIALIST_AVAILABILITY } from "../actions/available-specialist-actions";

function availableSpecialistsReducer (state = [], {type, payload}) {
  switch (type) {
    case GET_AVAILABLE_SPECIALISTS:
      return payload
    case UPDATE_SPECIALIST_AVAILABILITY:
      const updatedSpecialists = state.map(spec => {
        if (spec.specialistId === payload.specialistId) {
          spec.availability = payload.availability
        }

        return spec
      })

      return updatedSpecialists
    default:
      return state;
  }
}

export default availableSpecialistsReducer;
