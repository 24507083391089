import React from 'react'
import error404 from '../../assets/images/error404.png'

function ErrorPage() {
  return (
	<div className="error">
		<img src={error404} className="error__image" alt="Page Not Found" />
	</div>
  )
}

export default ErrorPage