import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

function LoaderTransparent(props) {
    return(
        <div className={props.loading ? 'loader loader--transparent flex flex--ai-center flex--jc-center flex--dir-column' : 
            'loader loader--transparent loader--loaded loader--no-effects flex flex--ai-center flex--jc-center flex--dir-column'}>
            <HashLoader size={100} color={'#71B2E2'}/>
            {/* <HashLoader size={100} color={'#f70077'}/> */}

            <h6 className={'loader__text'}>please wait while we book your appointment...</h6>
        </div>
    )
}

export default LoaderTransparent;