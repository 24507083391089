import { GET_MERCHANT_INFO } from '../actions/merchant-actions';

function merchantReducer (state = null, {type, payload}) {
  switch (type) {
    case GET_MERCHANT_INFO:
      return payload.merchant;
    default:
      return state;
  }
}

export default merchantReducer;
