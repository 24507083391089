import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { updateAppointmentServices, updateAppointmentDate, apiPostAppointment } from '../../actions/appointment-actions';
import { updateCustomerInformation } from '../../actions/customer-actions';
import { updatePage } from '../../actions/page-actions';

import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import * as EmailValidator from 'email-validator';
import 'react-day-picker/lib/style.css';
import "moment-timezone";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomerInfo(props) {
  const history = useHistory()

  const alias = props.match.params.alias
  const page = props.page

  const {
    date,
    time,
    count,
    service,
    specialist
  } = props.appointmentServices[0];

  const {
    name,
    notes,
    email,
    gender,
    birthDate,
    mobileNumber,
  } = props.customerInformation;

  const [isChecked] = useState('')

  const handlePrevClick = () => {
    const data = {
      step: 'stepFour',
      stepActive: 4,
    }

    props.handlePrev(data)
  }

  const handlePickerPress = (e) => {
    e.preventDefault();

    return false;
  }

  const handleBookClick = () => {
    if (!name || !mobileNumber || !email) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please fill up required fields to proceed.',
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        timerProgressBar: true,
      });
    } else {
      const merchantID = props.merchant.id;
      const appointmentServices = props.appointmentServices;
      let validateEmail = email ? EmailValidator.validate(email) : true;
      let selectedSpecialist = [];
      let requiredCounter = 0;
      let dataServices = [];
      let data = null;

      appointmentServices.forEach((service) => {
        if (service.service.length === 0 || service.time.length === 0) {
          requiredCounter += 1;
        }
      });

      if (requiredCounter > 0) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Please fill up all service information!',
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
        });

        return false;
      }

      let cleanMobile = mobileNumber.replace(/\D/g,'');
      if (cleanMobile.length < 10) {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Please enter valid mobile number',
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
        });

        return false;
      }

      if (!validateEmail) {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Please enter valid email address',
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
        });

        return false;
      }

      appointmentServices.forEach((data) => {
        let specialistID = data.specialist.specialistId;
        let autoAssignSpecialist = props.merchant.merchant_business.auto_assign_specialist;

        // Check if Any Specialist (Auto Assign Queue)
        if (parseInt(specialistID) === 0 && autoAssignSpecialist === 1) {

          const specialists = data.specialist.staffOptions;
          let autoAssign = {
            count: 0,
            id: 0,
          };

          specialists.forEach((sp, index) => {
            let timeAvailable = sp.availability;

            if (timeAvailable.includes(data.time)) {
              if (autoAssign.id === 0) {
                autoAssign = {
                  id: sp.id,
                  count: sp.appointmentCount,
                }
              }
            }
          });

          specialistID = autoAssign.id;

          selectedSpecialist.push(specialistID);
        } else {
          selectedSpecialist.push(specialistID);
        }

        if (!data.service) {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Please choose a service',
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            timerProgressBar: true,
          });
  
          return false;
        }

        // if (!specialistID) {
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'warning',
        //     title: 'Please choose a specialist',
        //     showConfirmButton: false,
        //     timer: 1500,
        //     toast: true,
        //     timerProgressBar: true,
        //   });
  
        //   return false;
        // }

        dataServices.push({
          service: data.service,
          specialist: specialistID,
          time: data.time,
          facility: 0
        });
      });

      data = {
        source_id: 3,
        origin: "EXTERNAL PAGE",
        reservation_date: date,
        guest_count: count >= 1 ? count : 1,
        notes: notes,
        customer_name: name,
        customer_mobile_number: cleanMobile,
        customer_email: email,
        customer_gender: gender,
        customer_birth_date: birthDate ? moment(birthDate).format('YYYY-MM-DD') : null,
        customer_membership: 1,
        info: [{
          name: name,
          details: dataServices
        }]
      };

      props.onApiPostAppointment(merchantID, data);
    }
  }

  const specialistName = (specialist === "") ? "Any Specialist" : specialist.specialistName

  return (
    <React.Fragment>
      <div className='customer__info-container'>
        <p className="customer__info__title mx-auto mb-5">Enter your details</p>

          <div className="customer__info__service justify-content-between">
            <label className="label text-muted">Service</label>
            <p>{service.title}</p>
          </div>
          <div className="customer__info__service justify-content-between">
            <label className="label text-muted">Staff</label>
            <p>{specialistName}</p>
          </div>
          <div className="customer__info__service justify-content-between">
            <label className="label text-muted">Date</label>
            <p>{moment(date).format('MMM DD, dddd, YYYY')}</p>
          </div>
          <div className="customer__info__service justify-content-between">
            <label className="label text-muted">Time</label>
            <p>{moment(time, 'HH:mm').format('hh:mm A')}</p>
          </div>

          <div className="customer__info__form mt-5">
            <div className="row">
              <div className="col-lg-6 customer__info__form-group">
                <label><span className="text-danger">*</span>Full Name</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Full name"
                  className="form-control"
                  maxlength="80"
                  value={name || ''}
                  onChange={props.handleInputChange}
                />
              </div>
              <div className="col-lg-6 customer__info__form-group">
                <label>Date of Birth</label>
                {/* <DayPickerInput
                  onDayChange={props.handleDayChange}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={'MMMM D YYYY'}
                  value={birthDate || ''}
                  placeholder={'Select Date'}
                  inputProps={{
                    className: 'form-control',
                    id: 'date-picker',
                    name: "birthDate",
                    onKeyDown: (e => handlePickerPress(e)),
                    readOnly: 'readonly'
                  }}
                  dayPickerProps={{
                    // disabledDays: new Date(),
                    toMonth: new Date()
                  }}
                /> */}

              <DatePicker
                    selected={birthDate ? new Date(birthDate) : ""}
                    onChange={props.handleDayChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    maxDate={new Date()}
                    placeholderText={'Date of birth'} 
                  />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 customer__info__form-group">
                <label>Gender</label>

                <div className="row">
                  <div className="col-lg-6 customer__info__form-group text-center">
                    <label className="customer__info__form-container">Male
                      <input
                        type="radio"
                        name="gender"
                        defaultChecked={isChecked === 'male' ? true : false}
                        value={"male"}
                        onChange={props.handleInputChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-lg-6 customer__info__form-group text-center">
                    <label className="customer__info__form-container">Female
                      <input
                        type="radio"
                        name="gender"
                        defaultChecked={isChecked === 'female' ? true : false}
                        value={"female"}
                        onChange={props.handleInputChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 customer__info__form-group">
                <label><span className="text-danger">*</span>Email</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  maxlength="80"
                  value={email || ''}
                  onChange={props.handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 customer__info__form-group">
                <label><span className="text-danger">*</span>Mobile Number</label>
                <InputMask
                  name="mobileNumber"
                  className="form-control"
                  placeholder="Mobile number"
                  value={mobileNumber || ''}
                  onChange={props.handleInputChange}
                  mask="(999) 999-9999" maskChar={null}
                />
              </div>
              <div className="col-lg-6 customer__info__form-group">
                <label>Notes</label>
                <input
                  name="notes"
                  type="text"
                  className="form-control"
                  placeholder="Reservation notes"
                  maxlength="100"
                  value={notes || ''}
                  onChange={props.handleInputChange}
                />
              </div>
            </div>
          </div>

          {props.merchant.merchant_business.enable_disclaimer 
            ? <div className={'user-acceptance'}>
            <p>By clicking "BOOK", I agree to the Privacy Policy and Terms of Use.</p>
            <p>This will serve as my electronic signature to allow <b>{process.env.REACT_APP_APP_NAME}</b> to use my information.</p>
            <p>I authorize <b>{process.env.REACT_APP_APP_NAME}</b> to send SMS, Email notificaiton or pre-recorded messages to the information provided.</p>
          </div> : ''}          
          
          <div className='d-flex justify-content-between my-4'>
            <Link to={`/${alias}/date`}>
              <button className="btn btn-secondary back" onClick={handlePrevClick}>Back</button>
            </Link>
            
            <button className="btn btn-primary next" onClick={handleBookClick}>Book</button>
          </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    appointmentDate: state.appointmentDate,
    appointmentServices: state.appointmentServices,
    customerInformation: state.customerInformation
  }
};

const mapActionsToProps = {
  onUpdateAppointmentServices: updateAppointmentServices,
  onUpdateCustomerInformation: updateCustomerInformation,
  onUpdateAppointmentDate: updateAppointmentDate,
  onApiPostAppointment: apiPostAppointment,
  onUpdatePage: updatePage
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(CustomerInfo));