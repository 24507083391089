import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import CalendarCircle from '../../assets/images/calendar.png';

function Summary(props) {
  const {
    date,
    time,
    count,
    service,
    specialist,
  } = props.appointmentServices[0]

  const handleAnotherClick = () => {
    const merchant = props.merchant;
    const alias = merchant.merchant_business.alias
    window.location.href = "/" + alias
  }

  return (
      <React.Fragment>
        <div className="summary">
          <h5 className="app-title pb-3 pt-5">Appointment Booking</h5>

          <div className='summary-container'>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <p className="summary__thanks text-center">
                <img src={CalendarCircle} alt="" /><br />
                Thank you! <br />
                your appointment is booked
              </p>
            </div>

            <div className="summary__details">
              <div className="form-group">
                <label className="label text-muted">Service</label>
                <p>{service ? service.title : ''}</p>
              </div>
              <div className="form-group">
                <label className="label text-muted">Staff</label>
                <p>{specialist ? specialist.specialistName : ''}</p>
              </div>

              <div className="summary__details-dt">
                <div className="form-group">
                  <label className="label text-muted">Date</label>
                  <p>{moment(date).format('MMM DD, dddd, YYYY')}</p>
                </div>
                <div className="form-group">
                  <label className="label text-muted">Time</label>
                  <p>{moment(time, 'HH:mm').format('hh:mm A')}</p>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-center pt-4'>
              <button className="btn btn-primary summary__button" onClick={handleAnotherClick}>Add another appointment</button>
            </div>
            
          </div>
        </div>
      </React.Fragment>
  );
}


const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    appointmentServices: state.appointmentServices,
    customerInformation: state.customerInformation
  }
};

export default connect(mapStateToProps)(Summary);