import axios from 'axios';
import moment from "moment/moment";
import * as Config from "../../utils/config";
import { updateAppointmentServiceSpecialist } from "./appointment-actions";
import { updateComponentLoading, updatePageLoading } from "./page-loader-actions";

export const GET_AVAILABLE_SPECIALISTS = 'specialist:getAvailableSpecialists';
export const UPDATE_SPECIALIST_AVAILABILITY = 'specialist:updateAvailability';

export function getAvailableSpecialists(specialists) {
  return {
    type: GET_AVAILABLE_SPECIALISTS,
    payload: specialists
  }
}

export function updateSpecialistAvailability(specialistData) {
  return {
    type: UPDATE_SPECIALIST_AVAILABILITY,
    payload: specialistData
  }
}

export function apiRequestAvailableSpecialists(merchantId, serviceId, appointmentDate = null, duration = 15, specialistId = 0, showLoading = true) {
  appointmentDate = appointmentDate ? appointmentDate : moment().format('YYYY-MM-DD')

  return dispatch => {
    if (showLoading) {
      dispatch(updateComponentLoading(true))
    }

    axios.get(`${Config.API_URL}/merchants/${merchantId}/services/${serviceId}/availability`, {
      headers: {
        'Authorization': Config.TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      params: {
        reservation_date: appointmentDate
      }
    })
      .then((response) => {
        const data = response.data.internalMessage;
        const specialists = data.specialists;

        const selectedDay = moment(appointmentDate, 'MMMM D YYYY').format('dddd').toLowerCase();
        const currentDate = moment().format('MMMM D YYYY');
        const currentTime = moment().add(0, 's');

        let specialistOptions = [];
        let timeAvailable = [];
        let staffOptions = [];

        if (specialists) {
          const sortAlphaNum = (a, b) => a.first_name.localeCompare(b.first_name, 'en', { numeric: true })
          specialists.sort(sortAlphaNum);

          specialists.forEach((specialist) => {

            if (specialist.contract[`operation_${selectedDay}`]) {
              const start = parseInt(moment(specialist.contract[`operation_${selectedDay}_from`], 'hh:mm A').format('X'));
              let end = parseInt(moment(specialist.contract[`operation_${selectedDay}_to`], 'hh:mm A').format('X'));

              const details = specialist.reservation_details;
              let specialistTime = [];
              let formatSpecialistTime = [];

              // Create specialist time availability
              for (let i = start; i <= end; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                if (appointmentDate === currentDate) {
                  if (currentTime < moment(i, 'X')) {
                    specialistTime.push(moment(i, 'X').format('HH:mm'));
                  }
                } else {
                  specialistTime.push(moment(i, 'X').format('HH:mm'));
                }
              }

              // Record specialist break time
              let specialistBreakTime = []
              if (specialist.contract[`operation_${selectedDay}_breaks`] && specialist.contract[`operation_${selectedDay}_breaks`].length) {
                specialist.contract[`operation_${selectedDay}_breaks`].forEach(b => {
                  // console.log(specialist.first_name, 'b from ', b)
                  let bStart = parseInt(moment(b.startTime, 'hh:mm A').format('X'));
                  let bEnd = parseInt(moment(b.endTime, 'hh:mm A').format('X'));

                  for (let i = bStart; i < bEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                    specialistBreakTime.push(moment(i, 'X').format('HH:mm'));
                  }
                })
              }

              // Remove available time based on appointments
              if (details.length > 0) {
                details.forEach((detail) => {
                  if (detail.status !== 'CANCELLED') {
                    // Remove unavailable time based on appointments
                    const dCurrent = parseInt(moment(detail.reservation_time, 'HH:mm').format('X'));
                    const dEnd = parseInt(moment(detail.reservation_time_end, 'HH:mm').format('X'));
                    for (let i = dCurrent; i < dEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                      let index = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                      if (index >= 0) {
                        specialistTime.splice(index, 1);
                      }
                    }

                    // Remove unavailable time based on duration of selected service
                    let reverseCurrent = moment(detail.reservation_time, 'HH:mm').subtract(15, 'm');
                    let reverseTimeEnd = moment(detail.reservation_time, 'HH:mm').subtract(duration, 'm');
                    let intRCurrent = parseInt(reverseCurrent.format('X'));
                    let intREnd = parseInt(reverseTimeEnd.format('X'));

                    for (let i = intRCurrent; i > intREnd; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                      let reverseIndex = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                      if (reverseIndex >= 0) {
                        specialistTime.splice(reverseIndex, 1);
                      }
                    }
                  }
                });
              }

              // Remove available time when specialist is on break
              if (specialistBreakTime.length > 1) {
                specialistBreakTime.forEach((breakTime) => {
                  let pos = specialistTime.indexOf(breakTime);

                  if (pos >= 0) {
                    specialistTime.splice(pos, 1);
                  }
                });
              }

              /**
               * Gil Update
               * Sanitize computed available time (round off to nearest quarter)
               */
              specialistTime = specialistTime.map(time => {
                let minutes = parseInt(moment(time, 'HH:mm').format('mm'))
                if (minutes % 15 === 0) {
                  return time
                } else {
                  const timeIntervals = [0, 15, 30, 45, 60]
                  for (let i = 0; i < timeIntervals.length; i++) {
                    if (minutes > timeIntervals[i] && minutes < timeIntervals[i + 1]) {
                      let newMins = timeIntervals[i + 1]
                      return moment(time, 'HH:mm').startOf('hour').add(newMins, 'minute').format('HH:mm')
                    }
                  }
                }
              })

              if (specialistTime.length > 0) {
                specialistTime.forEach((time) => {
                  formatSpecialistTime.push(moment(time, 'HH:mm').format('h:mm A'));

                  if (!timeAvailable.includes(time)) {
                    timeAvailable.push(time);
                  }
                })

                // For auto assign data check
                staffOptions.push({
                  id: specialist.id,
                  availability: formatSpecialistTime,
                  appointmentCount: specialist.reservation_details.length
                })

                specialistOptions.push({
                  specialistName: specialist.first_name + ' ' + specialist.last_name,
                  specialistId: specialist.id,
                  availability: formatSpecialistTime,
                });
              }
            }
          });

          //let isAnySpecialistEnable = 0;
          if (specialistOptions.length > 0) {
            timeAvailable = timeAvailable.sort();
            let formatTimeAvailable = [];

            timeAvailable.forEach((t) => {
              formatTimeAvailable.push(moment(t, 'HH:mm').format('h:mm A'));
            });

            // Add any specialist option
            const anySpecialist = {
              specialistName: 'Any employee',
              specialistId: 0,
              availability: formatTimeAvailable,
              staffOptions: staffOptions,
            }

            specialistOptions.splice(0, 0, anySpecialist);
          }
        }

        
        if (specialistId === 0) {
          let strTime = moment().startOf('day');

          const interval = 15; //minutes
          const numberOfTimes = (24 * 60) / interval;

          const timeCollection = [];
          for (let i = 0; numberOfTimes > i; i++) {
            timeCollection.push(strTime.format('HH:mm'));
            strTime = moment(strTime).add(interval, 'minutes');
          }

          const specialistOptions = {
            specialistName: 'Any employee',
            specialistId: 0,
            availability: timeCollection,
            staffOptions: staffOptions,
          }

          // If called by default, assume (any spec is selected), update time availability
          dispatch(updateAppointmentServiceSpecialist(specialistOptions))
        }

        dispatch(getAvailableSpecialists(specialistOptions))
        dispatch(updateComponentLoading(false))
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export function apiRequestSpecialistAvailability(merchantId, selectedSpecialistId, serviceId, appointmentDate, duration = 15, showLoading = true) {
  appointmentDate = appointmentDate ? appointmentDate : moment().format('YYYY-MM-DD')

  return dispatch => {
    if (showLoading) {
      dispatch(updateComponentLoading(true))
    }

    axios.get(`${Config.API_URL}/v2/merchants/${merchantId}/specialists/${selectedSpecialistId}`, {
      headers: {
        'Authorization': Config.TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      params: {
        reservation_date: appointmentDate,
        get_contract: 1,
        get_reservation_details: 1
      }
    })
      .then((response) => {
        const specialist = response.data.internalMessage;

        const selectedDay = moment(appointmentDate, 'YYYY-MM-DD').format('dddd').toLowerCase();
        const currentDate = moment().format('MMMM D YYYY');
        const currentTime = moment().add(0, 's');

        let specialistData = null;
        let timeAvailable = [];
        console.log('throw------');
        console.log(specialist);
        console.log('catch------');
        if (specialist) {
          if (specialist.contract[`operation_${selectedDay}`] === 1) {
            const start = parseInt(moment(specialist.contract[`operation_${selectedDay}_from`], 'hh:mm A').format('X'));
            let end = parseInt(moment(specialist.contract[`operation_${selectedDay}_to`], 'hh:mm A').format('X'));

            const details = specialist.reservation_details;
            let specialistTime = [];
            let formatSpecialistTime = [];

            // Create specialist time availability
            for (let i = start; i <= end; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
              if (appointmentDate === currentDate) {
                if (currentTime < moment(i, 'X')) {
                  specialistTime.push(moment(i, 'X').format('HH:mm'));
                }
              } else {
                specialistTime.push(moment(i, 'X').format('HH:mm'));
              }
            }

            // Record specialist break time
            let specialistBreakTime = []
            if (specialist.contract[`operation_${selectedDay}_breaks`] && specialist.contract[`operation_${selectedDay}_breaks`].length) {
              specialist.contract[`operation_${selectedDay}_breaks`].forEach(b => {
                // console.log(specialist.first_name, 'b from ', b)
                let bStart = parseInt(moment(b.startTime, 'hh:mm A').format('X'));
                let bEnd = parseInt(moment(b.endTime, 'hh:mm A').format('X'));

                for (let i = bStart; i < bEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                  specialistBreakTime.push(moment(i, 'X').format('HH:mm'));
                }
              })
            }

            // Remove available time based on appointments
            if (details.length > 0) {
              details.forEach((detail) => {
                if (detail.status !== 'CANCELLED') {
                  // Remove unavailable time based on appointments
                  const dCurrent = parseInt(moment(detail.reservation_time, 'HH:mm').format('X'));
                  const dEnd = parseInt(moment(detail.reservation_time_end, 'HH:mm').format('X'));
                  for (let i = dCurrent; i < dEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                    let index = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                    if (index >= 0) {
                      specialistTime.splice(index, 1);
                    }
                  }

                  // Remove unavailable time based on duration of selected service
                  let reverseCurrent = moment(detail.reservation_time, 'HH:mm').subtract(15, 'm');
                  let reverseTimeEnd = moment(detail.reservation_time, 'HH:mm').subtract(duration, 'm');
                  let intRCurrent = parseInt(reverseCurrent.format('X'));
                  let intREnd = parseInt(reverseTimeEnd.format('X'));

                  for (let i = intRCurrent; i > intREnd; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                    let reverseIndex = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                    if (reverseIndex >= 0) {
                      specialistTime.splice(reverseIndex, 1);
                    }
                  }
                }
              });
            }

            // Remove available time when specialist is on break
            if (specialistBreakTime.length > 1) {
              specialistBreakTime.forEach((breakTime) => {
                let pos = specialistTime.indexOf(breakTime);

                if (pos >= 0) {
                  specialistTime.splice(pos, 1);
                }
              });
            }

            /**
             * Gil Update
             * Sanitize computed available time (round off to nearest quarter)
             */
            specialistTime = specialistTime.map(time => {
              let minutes = parseInt(moment(time, 'HH:mm').format('mm'))
              if (minutes % 15 === 0) {
                return time
              } else {
                const timeIntervals = [0, 15, 30, 45, 60]
                for (let i = 0; i < timeIntervals.length; i++) {
                  if (minutes > timeIntervals[i] && minutes < timeIntervals[i + 1]) {
                    let newMins = timeIntervals[i + 1]
                    return moment(time, 'HH:mm').startOf('hour').add(newMins, 'minute').format('HH:mm')
                  }
                }
              }
            })

            if (specialistTime.length > 0) {
              specialistTime.forEach((time) => {
                formatSpecialistTime.push(moment(time, 'HH:mm').format('h:mm A'));

                if (!timeAvailable.includes(time)) {
                  timeAvailable.push(time);
                }
              })
            }

            specialistData = {
              specialistName: specialist.first_name + ' ' + specialist.last_name,
              specialistId: specialist.id,
              availability: formatSpecialistTime
            }
          } else {
            specialistData = {
              specialistName: specialist.first_name + ' ' + specialist.last_name,
              specialistId: specialist.id,
              availability: []
            }
          }
        }

        dispatch(updateAppointmentServiceSpecialist(specialistData))
        // dispatch(updateSpecialistAvailability(specialistData))
        dispatch(updateComponentLoading(false))
      })
      .catch((error) => {
        console.log(error);
      });
  }
}