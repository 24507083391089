import React from 'react';
import axios from 'axios';
import * as Config from '../../utils/config';

import { connect } from 'react-redux';
import { apiRequestMerchantInfo } from '../actions/merchant-actions';
import { updatePage } from '../actions/page-actions';

import Header from '../layouts/header-layout';
import Footer from '../layouts/footer-layout';
import Loader from '../layouts/loader-layout';
import Appointment from '../appointment/Appointment';
import AppointmentDetails from '../appointmentDetails/AppointmentDetails';
import ErrorPage from '../pages/error-page';

import moment from "moment";
import "moment-timezone";

import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import TagManager from 'react-gtm-module'

class PinkApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            currentLatitude: "",
            currentLongitude: ""
        }
    }

    getGoelocation() {
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({
                currentLatitude: position.coords.latitude, 
                currentLongitude: position.coords.longitude
            })
        });
    }

    tracking() {
        const state = this.state;
        const merchant = this.props.merchant;
        let params = new URLSearchParams(window.location.search);

        if (!Array.isArray(merchant)) {
            const location = [
                { 'lat': state.currentLatitude },
                { 'lng': state.currentLongitude },
            ]

            const isEmpty = Object.keys(params).length !== 0;
            if (!isEmpty) {
                let data = {
                    merchant_id: this.props.merchant.id,
                    utm_id: params.get('utm_id'),
                    utm_source: params.get('utm_source'),
                    utm_medium: params.get('utm_medium'),
                    utm_content: params.get('utm_content'),
                    visitor_location: JSON.stringify(location),
                    pageview: 1
                }
    
                axios({
                    method: 'post',
                    url: `${Config.API_URL}/merchants/${merchant.id}/utm/tracking`,
                    headers: {
                        'Authorization': Config.TOKEN,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    data: data
                })
            }
        }
    }

    componentDidMount() {
        document.title = ""
        const path = window.location.pathname;
        const id = path.replace('/', '');

        if (!path.includes("/appointments/")) {
            this.props.onApiRequestMerchantInfo(id ? id : 0);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.merchant !== prevProps.merchant) {
            this.getGoelocation()
            setTimeout(() => {
                this.setState({
                    loading: false
                });
            }, 1000);

            if (this.props.merchant === 'error') {
                this.props.onUpdatePage('error');
            } else if (this.props.merchant.status === 0) {
                this.props.onUpdatePage('error');
            } else if (this.props.merchant.merchant_business.enable_customer_appointment_application == 0) {
                this.props.onUpdatePage('unavailable');
            } else {
                document.title = `${this.props.merchant.business_name} - ${this.props.merchant.merchant_category.title} in the city`

                // moment.tz.setDefault(this.props.merchant.merchant_business.merchant_timezone.application_value);
            }
        }
        if (prevState.currentLatitude != this.state.currentLatitude) {
            this.tracking();
        }
    }

    render() {
        let data = this.state;
        let prop = this.props;
        let title = 'Go3Reservation: Appointments';
        let description = 'Provides customer access to book an appointment in Go3Reservation Platform from a merchant website';
        let services = [];

        if (prop.merchant.merchant_category) {
            if (prop.merchant.merchant_category.meta_descriptions) {
                if (prop.merchant.merchant_category.meta_descriptions != '' || prop.merchant.merchant_category.meta_descriptions != null) {
                    description = prop.merchant.merchant_category.meta_descriptions;
                    description = description.replaceAll('[merchantCategory]', prop.merchant.merchant_category.title.toLowerCase());
                    description = description.replaceAll('[merchantName]', prop.merchant.business_name);
                    description = description.replaceAll('[merchantCity]', prop.merchant.city);

                    if (prop.merchant.service_categories) {
                        if (prop.merchant.service_categories.length > 3) {
                            for (let i = 0; i < 3; i++) {
                                services.push(prop.merchant.service_categories[i].title.toLowerCase());
                            }
                        } else {
                            for (let i = 0; i < prop.merchant.service_categories.length; i++) {
                                services.push(prop.merchant.service_categories[i].title.toLowerCase());
                            }
                        }
                        description = description.replaceAll('[merchantServices]', services);
                    }
                }
            }
            title = prop.merchant.business_name + ' - ' + prop.merchant.merchant_category.title + ' in ' + this.props.merchant.city + ' | Go3 Reservation';
        }

        console.log('title -', title);
        console.log('meta description -', description);

        return (
            <Router>
                <Switch>
                    {/* Main Booking Page */}
                    <Route exact path="/:alias" >
                        <React.Fragment>
                            <Helmet>
                                <title>{`${title.replace(/&amp;/g, '&')}`}</title>
                                <meta name="description" content={`${description}`} />
                            </Helmet>

                            <div className={'page-bg'}></div>

                            <Header merchant={prop.merchant} />

                            <main className={'main appointment'}>
                                <div className={'container'}>
                                    <Appointment currentPage={prop.page} />
                                </div>
                            </main>

                            <Footer merchant={prop.merchant} />

                            <Loader loading={data.loading} />
                        </React.Fragment>
                    </Route>

                    {/* Render Customer Details */}
                    <Route exact path="/appointments/:refCode/details/:refNumber" component={AppointmentDetails} />

                    {/* Catch unkown routes */}
                    <Route path="*">
                        <ErrorPage />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        page: state.page,
    }
};


const mapActionsToProps = {
    onApiRequestMerchantInfo: apiRequestMerchantInfo,
    onUpdatePage: updatePage,
};

const tagManagerArgs = {
    gtmId: 'GTM-KW86PK5',
    events: {
        'gtm.start': new Date().getTime(),
        event:'gtm.js'
    }
}

TagManager.initialize(tagManagerArgs)

export default connect(mapStateToProps, mapActionsToProps)(withRouter(PinkApp));