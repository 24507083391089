import React from 'react';
import {connect} from 'react-redux';
import {updateAppointmentServices} from '../../../actions/appointment-actions';
import axios from 'axios';
import * as Config from "../../../../utils/config";
import Select from 'react-select'
import moment from 'moment-timezone';
import _ from 'lodash'

import Swal from 'sweetalert2';

import {ReactComponent as Bin} from '../../../../assets/images/bin.svg';

class AppointmentServices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allSpecialistOptions: [],
            specialistOptions: [],
            initTimeOptions: [],
            timeOptions: [],
            specialistLoader: false,
            timeLoader: false,
            appointmentServices: [],
            availabilityPlaceHolder: [],
            specialistPlaceHolder: "",
            timePlaceHolder: "",
            hasSpecialist: false,
            newTime: {}
        }
    }

    getServiceAvailability(serviceID, duration) {
        const id = this.props.id;
        const appointmentServices = this.props.appointmentServices;
        const merchantBusiness = this.props.merchant.merchant_business;
        moment.tz.setDefault(merchantBusiness.merchant_timezone.application_value);

        const selectedDay = moment(this.props.appointmentDate, 'MMMM D YYYY').format('dddd').toLowerCase();
        let businessEndTime = moment(merchantBusiness[`regular_${selectedDay}_to`], 'h:mm A').format('X');
        const currentDate = moment().format('MMMM D YYYY');
        const currentTime = moment().add(0, 's');

        if (merchantBusiness[`reservation_cutoff_${selectedDay}`]) {
            businessEndTime = moment(merchantBusiness[`reservation_cutoff_${selectedDay}`], 'h:mm A').format('X');
        }

        // let time = moment(merchantBusiness[`regular_${selectedDay}_from`], 'h:mm A');
        // let initTimeOptions = [];

        // if (this.props.appointmentDate === currentDate) {
        //     do {
        //         if (currentTime < time) {
        //             initTimeOptions.push(time.format('h:mm A'));
        //         }

        //         time = time.add(15, 'minutes');
        //     } while (time <= endTime);
        // } else {
        //     do {
        //         initTimeOptions.push(time.format('h:mm A'));

        //         time = time.add(15, 'minutes');
        //     } while (time <= endTime);
        // }

        axios.get(`${Config.API_URL}/merchants/${this.props.merchant.id}/services/${serviceID}/availability`, {
            headers: {
                'Authorization': Config.TOKEN,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            params: {
                reservation_date: moment(this.props.appointmentDate, 'MMMM D YYYY').format('YYYY-MM-DD')
            }
        })
            .then((response) => {
                const data = response.data.internalMessage;
                const specialists = data.specialists;
                let specialistOptions = [];
                let timeAvailable = [];
                let staffOptions = [];

                if (specialists) {
                    const sortAlphaNum = (a, b) => a.first_name.localeCompare(b.first_name, 'en', { numeric: true })
                    specialists.sort( sortAlphaNum );

                    specialists.forEach((specialist) => {

                        if (specialist.contract[`operation_${selectedDay}`]) {
                            const start = parseInt(moment(specialist.contract[`operation_${selectedDay}_from`], 'hh:mm A').format('X'));
                            let end = parseInt(moment(specialist.contract[`operation_${selectedDay}_to`], 'hh:mm A').format('X'));

                            // If End time of specialist (>) business end time
                            if (parseInt(end) > parseInt(businessEndTime)) {
                                end = businessEndTime
                            }

                            const details = specialist.reservation_details;
                            let specialistTime = [];
                            let formatSpecialistTime = [];

                            // Create specialist time availability
                            for (let i = start; i <= end; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                if (this.props.appointmentDate === currentDate) {
                                    if (currentTime < moment(i, 'X')) {
                                        specialistTime.push(moment(i, 'X').format('HH:mm'));
                                    }
                                } else {
                                    specialistTime.push(moment(i, 'X').format('HH:mm'));
                                }
                            }
                            
                            // Record specialist break time
                            let specialistBreakTime = []
                            if (specialist.contract[`operation_${selectedDay}_breaks`] && specialist.contract[`operation_${selectedDay}_breaks`].length) {
                                specialist.contract[`operation_${selectedDay}_breaks`].forEach( b => {
                                    // console.log(specialist.first_name, 'b from ', b)
                                    let bStart = parseInt(moment(b.startTime, 'hh:mm A').format('X'));
                                    let bEnd = parseInt(moment(b.endTime, 'hh:mm A').format('X'));

                                    for (let i = bStart; i < bEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                        specialistBreakTime.push(moment(i, 'X').format('HH:mm'));
                                    }
                                })
                            }
                            
                            // Restrict booking at the same time
                            // if (appointmentServices.length > 1) {
                            //     appointmentServices.forEach((apt, key) => {
                            //         if (key !== id) {
                            //             const aptDuration = apt.service.duration;
                            //             const aptStartTime = parseInt(moment(apt.time.value, 'h:mm A').format('X'));
                            //             const aptEndTime = parseInt(moment(apt.time.value, 'h:mm A').add(aptDuration, 'm').format('X'));
                            //             const aptReverseStartTime = parseInt(moment(apt.time.value, 'h:mm A').subtract(15, 'm').format('X'));
                            //             const aptReverseEndTime = parseInt(moment(apt.time.value, 'h:mm A').subtract(duration, 'm').format('X'));

                            //             for (let i = aptStartTime; i < aptEndTime; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                            //                 let pos = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                            //                 if (pos >= 0) {
                            //                     specialistTime.splice(pos, 1);
                            //                 }
                            //             }

                            //             for (let i = aptReverseStartTime; i > aptReverseEndTime; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                            //                 let revPos = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                            //                 if (revPos >= 0) {
                            //                     specialistTime.splice(revPos, 1);
                            //                 }
                            //             }
                            //         }
                            //     });
                            // }

                            // Allow booking of same time but different specialist
                            if (appointmentServices.length > 1) {
                                appointmentServices.forEach((apt, key) => {
                                    if (key !== id && specialist.id === apt.specialist.value) {
                                        const aptDuration = apt.service.duration;
                                        const aptStartTime = parseInt(moment(apt.time.value, 'h:mm A').format('X'));
                                        const aptEndTime = parseInt(moment(apt.time.value, 'h:mm A').add(aptDuration, 'm').format('X'));
                                        const aptReverseStartTime = parseInt(moment(apt.time.value, 'h:mm A').subtract(15, 'm').format('X'));
                                        const aptReverseEndTime = parseInt(moment(apt.time.value, 'h:mm A').subtract(duration, 'm').format('X'));

                                        for (let i = aptStartTime; i < aptEndTime; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                            let pos = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (pos >= 0) {
                                                specialistTime.splice(pos, 1);
                                            }
                                        }

                                        for (let i = aptReverseStartTime; i > aptReverseEndTime; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                                            let revPos = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (revPos >= 0) {
                                                specialistTime.splice(revPos, 1);
                                            }
                                        }
                                    }
                                });
                            }

                            if (details.length > 0) {
                                details.forEach((detail) => {
                                    if (details.status !== 'CANCELLED') {
                                        // Remove unavailable time based on appointments
                                        const dCurrent = parseInt(moment(detail.reservation_time, 'HH:mm').format('X'));
                                        const dEnd = parseInt(moment(detail.reservation_time_end, 'HH:mm').format('X'));
                                        for (let i = dCurrent; i < dEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                            let index = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (index >= 0) {
                                                specialistTime.splice(index, 1);
                                            }
                                        }

                                        // Remove unavailable time based on duration of selected service
                                        let reverseCurrent = moment(detail.reservation_time, 'HH:mm').subtract(15, 'm');
                                        let reverseTimeEnd = moment(detail.reservation_time, 'HH:mm').subtract(duration, 'm');
                                        let intRCurrent = parseInt(reverseCurrent.format('X'));
                                        let intREnd = parseInt(reverseTimeEnd.format('X'));

                                        for (let i = intRCurrent; i > intREnd; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                                            let reverseIndex = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (reverseIndex >= 0) {
                                                specialistTime.splice(reverseIndex, 1);
                                            }
                                        }
                                    }
                                });
                            }

                            // Remove available time when specialist is on break
                            if (specialistBreakTime.length > 1) {
                                specialistBreakTime.forEach((breakTime) => {
                                    let pos = specialistTime.indexOf(breakTime);

                                    if (pos >= 0) {
                                        specialistTime.splice(pos, 1);
                                    }
                                });
                            }

                            /**
                             * Gil Update
                             * Sanitize computed available time (round off to nearest quarter)
                             */
                            specialistTime = specialistTime.map(time => {
                                let minutes = parseInt(moment(time, 'HH:mm').format('mm'))
                                if (minutes % 15 === 0 ) {
                                    return time
                                } else {
                                    const timeIntervals = [0, 15, 30, 45, 60]
                                    for (let i = 0; i < timeIntervals.length; i++) {
                                        if (minutes > timeIntervals[i] && minutes < timeIntervals[i+1]) {
                                            let newMins = timeIntervals[i+1]
                                            return moment(time, 'HH:mm').startOf('hour').add(newMins, 'minute').format('HH:mm')
                                        }
                                    }
                                }
                            })

                            if (specialistTime.length > 0) {
                                specialistTime.forEach((time) => {
                                    formatSpecialistTime.push(moment(time, 'HH:mm').format('h:mm A'));

                                    if (!timeAvailable.includes(time)) {
                                        timeAvailable.push(time);
                                    }
                                })

                                // For auto assign data check
                                staffOptions.push({
                                    id: specialist.id,
                                    availability: formatSpecialistTime,
                                    appointmentCount: specialist.reservation_details.length
                                })

                                specialistOptions.push({
                                    label: specialist.first_name + ' ' + specialist.last_name,
                                    value: specialist.id,
                                    availability: formatSpecialistTime,
                                });
                            }
                        }
                    });

                    setTimeout(() => {
                        //let isAnySpecialistEnable = 0;
                        if (specialistOptions.length > 0) {
                            timeAvailable = timeAvailable.sort();
                            let formatTimeAvailable = [];

                            timeAvailable.forEach((t) => {
                                formatTimeAvailable.push(moment(t, 'HH:mm').format('h:mm A'));
                            });

                            let anySpecialist = "";
                            if (parseInt(merchantBusiness.show_any_specialist) === 1) {
                                anySpecialist = {
                                    label: 'Any Specialist',
                                    value: 0,
                                    availability: formatTimeAvailable,
                                    staffOptions: staffOptions,
                                }

                                specialistOptions.splice(0, 0, anySpecialist);
                            }

                            if (Array.isArray(appointmentServices[id].specialist)) {
                                //appointmentServices[id].specialist = anySpecialist;
                                if (parseInt(merchantBusiness.show_any_specialist) === 1) {
                                    this.handleSpecialistChange(anySpecialist);
                                }
                            } else {
                                specialistOptions.forEach((option) => {
                                    if (appointmentServices[id].specialist.value === option.value) {
                                        this.handleSpecialistChange(option);
                                    }
                                });
                            }

                            this.setState({
                                appointmentServices: appointmentServices
                            });

                            this.props.onUpdateAppointmentServices(appointmentServices);
                        }

                        this.setState({
                            specialistOptions: specialistOptions,
                            allSpecialistOptions: specialistOptions,
                            specialistLoader: false,
                            timeLoader: false
                        });

                    }, 500);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    /**
     * For placeholder only
     * @param serviceID
     * @param duration
     */
    getServiceAvailabilityForPlaceHolder(serviceID, duration) {
        const id = this.props.id;
        const appointmentServices = this.props.appointmentServices;
        const merchantBusiness = this.props.merchant.merchant_business;
        moment.tz.setDefault(merchantBusiness.merchant_timezone.application_value);

        console.log("APPOINTMENT DATE===============");

        let appointmentDate = this.props.appointmentDate;
        let aDateValidation = moment(this.props.appointmentDate, 'YYYY-MM-DD', true);
        if(!aDateValidation.isValid()) {
            appointmentDate = moment().format('MMMM D YYYY');
        }



        const selectedDay = moment(appointmentDate, 'MMMM D YYYY').format('dddd').toLowerCase();
        let businessEndTime = moment(merchantBusiness[`regular_${selectedDay}_to`], 'h:mm A').format('X');
        const currentDate = moment().format('MMMM D YYYY');
        const currentTime = moment().add(0, 's');

        if (merchantBusiness[`reservation_cutoff_${selectedDay}`]) {
            businessEndTime = moment(merchantBusiness[`reservation_cutoff_${selectedDay}`], 'h:mm A').format('X');
        }

        axios.get(`${Config.API_URL}/merchants/${this.props.merchant.id}/services/${serviceID}/availability`, {
            headers: {
                'Authorization': Config.TOKEN,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            params: {
                reservation_date: moment(appointmentDate, 'MMMM D YYYY').format('YYYY-MM-DD')
            }
        })
            .then((response) => {
                const data = response.data.internalMessage;
                const specialists = data.specialists;
                let specialistOptions = [];
                let timeAvailable = [];
                let staffOptions = [];

                if (specialists) {
                    specialists.forEach((specialist) => {

                        if (specialist.contract[`operation_${selectedDay}`]) {
                            const start = parseInt(moment(specialist.contract[`operation_${selectedDay}_from`], 'hh:mm A').format('X'));
                            let end = parseInt(moment(specialist.contract[`operation_${selectedDay}_to`], 'hh:mm A').format('X'));

                            // If End time of specialist (>) business end time
                            if (parseInt(end) > parseInt(businessEndTime)) {
                                end = businessEndTime
                            }

                            const details = specialist.reservation_details;
                            let specialistTime = [];
                            let formatSpecialistTime = [];

                            // Create specialist time availability
                            for (let i = start; i <= end; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                if (appointmentDate === currentDate) {
                                    if (currentTime < moment(i, 'X')) {
                                        specialistTime.push(moment(i, 'X').format('HH:mm'));
                                    }
                                } else {
                                    specialistTime.push(moment(i, 'X').format('HH:mm'));
                                }
                            }

                            // Remove unavailable time based on exsisting specialist
                            if (appointmentServices.length > 1) {
                                appointmentServices.forEach((apt, key) => {

                                    if (key !== id) {
                                        const aptDuration = apt.service.duration;
                                        const aptStartTime = parseInt(moment(apt.time.value, 'h:mm A').format('X'));
                                        const aptEndTime = parseInt(moment(apt.time.value, 'h:mm A').add(aptDuration, 'm').format('X'));
                                        const aptReverseStartTime = parseInt(moment(apt.time.value, 'h:mm A').subtract(15, 'm').format('X'));
                                        const aptReverseEndTime = parseInt(moment(apt.time.value, 'h:mm A').subtract(duration, 'm').format('X'));

                                        for (let i = aptStartTime; i < aptEndTime; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                            let pos = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (pos >= 0) {
                                                specialistTime.splice(pos, 1);
                                            }
                                        }

                                        for (let i = aptReverseStartTime; i > aptReverseEndTime; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                                            let revPos = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (revPos >= 0) {
                                                specialistTime.splice(revPos, 1);
                                            }
                                        }
                                    }
                                });
                            }

                            if (details.length > 0) {
                                details.forEach((detail) => {
                                    if (details.status !== 'CANCELLED') {
                                        // Remove unavailable time based on appointments
                                        const dCurrent = parseInt(moment(detail.reservation_time, 'HH:mm').format('X'));
                                        const dEnd = parseInt(moment(detail.reservation_time_end, 'HH:mm').format('X'));
                                        for (let i = dCurrent; i < dEnd; i = parseInt(moment(i, 'X').add(15, 'm').format('X'))) {
                                            let index = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (index >= 0) {
                                                specialistTime.splice(index, 1);
                                            }
                                        }

                                        // Remove unavailable time based on duration of selected service
                                        let reverseCurrent = moment(detail.reservation_time, 'HH:mm').subtract(15, 'm');
                                        let reverseTimeEnd = moment(detail.reservation_time, 'HH:mm').subtract(duration, 'm');
                                        let intRCurrent = parseInt(reverseCurrent.format('X'));
                                        let intREnd = parseInt(reverseTimeEnd.format('X'));

                                        for (let i = intRCurrent; i > intREnd; i = parseInt(moment(i, 'X').subtract(15, 'm').format('X'))) {
                                            let reverseIndex = specialistTime.indexOf(moment(i, 'X').format('HH:mm'));

                                            if (reverseIndex >= 0) {
                                                specialistTime.splice(reverseIndex, 1);
                                            }
                                        }
                                    }
                                });
                            }

                            if (specialistTime.length > 0) {
                                specialistTime.forEach((time) => {
                                    formatSpecialistTime.push(moment(time, 'HH:mm').format('h:mm A'));

                                    if (!timeAvailable.includes(time)) {
                                        timeAvailable.push(time);
                                    }
                                })

                                // For auto assign data check
                                staffOptions.push({
                                    id: specialist.id,
                                    availability: formatSpecialistTime,
                                    appointmentCount: specialist.reservation_details.length
                                })

                                specialistOptions.push({
                                    label: specialist.first_name + ' ' + specialist.last_name,
                                    value: specialist.id,
                                    availability: formatSpecialistTime,
                                });
                            }
                        }
                    });

                    setTimeout(() => {
                        console.log(specialistOptions);
                        let forPlaceHolder = [];
                        if(specialistOptions.length > 0) {
                            if(specialistOptions[0].availability.length > 0) {
                                forPlaceHolder.push({
                                    staff: specialistOptions[0].label,
                                    time: specialistOptions[0].availability[0]
                                })
                            } else {
                                forPlaceHolder.push({
                                    staff: specialistOptions[0].label,
                                    time: "12:00 AM"
                                })
                            }
                        }
                        this.setState({
                            availabilityPlaceHolder: forPlaceHolder
                        })
                    }, 500);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getAvailableTime(time) {
        let index = this.props.id;
        let services = this.props.appointmentServices;
        let timeOptions = [];

        time.forEach((t) => {
            timeOptions.push({
                label: t,
                value: t
            });
        });

        this.setState({
            timeOptions: timeOptions,
            timeLoader: false,
        });

        if (!time.includes(services[index].time.value)) {
            services[index].time = [];

            this.setState({
                appointmentServices: services,
            });

            this.props.onUpdateAppointmentServices(services);
        }
    }

    getAvailableSpecialist(time) {
        const specialists = this.state.allSpecialistOptions;
        let index = this.props.id;
        let services = this.props.appointmentServices;
        let specialistOptions = [];

        specialists.forEach((specialist) => {
            if (specialist.availability.includes(time)) {
                specialistOptions.push(specialist)
            }
        });

        this.setState({
            specialistOptions: specialistOptions
        });

        if (!Array.isArray(services[index].specialist) && !services[index].specialist.availability.includes(time)) {
            services[index].specialist = [];

            this.setState({
                appointmentServices: services,
            });

            this.props.onUpdateAppointmentServices(services);
        }
    }

    handleServiceChange(option) {
        let index = this.props.id;
        let services = this.props.appointmentServices;
        let date = this.props.appointmentDate;

        if (date.length === 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please select a date first!',
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
            });

            return false;
        }

        services[index] = {
            service: option,
            specialist: [],
            time: []
        };

        this.setState({
            specialistOptions: [],
            timeOptions: [],
            specialistLoader: true,
            timeLoader: true,
            appointmentServices: services
        });

        this.props.onUpdateAppointmentServices(services);

        this.getServiceAvailability(option.value, option.duration);
    }

    handleSpecialistChange(option) {
        const merchant = this.props.merchant
        const merchant_business = merchant.merchant_business;
        
        let index = this.props.id;
        let services = this.props.appointmentServices;
        let show_time_selection = (merchant_business)? merchant_business.show_time_selection_on_different_specialists: "";

        services[index].specialist = option;

        this.setState({
            appointmentServices: services
        });

        this.props.onUpdateAppointmentServices(services);
        this.getAvailableTime(option.availability);

        if (!show_time_selection) {
            if (index >= 1) {
                setTimeout(() => {
                  this.setServiceTime()
                }, 200)
              }
        } else {
            setTimeout(() => {
                this.setServiceTimeOther(option)
            }, 200)
        }
    }

    setServiceTimeOther(option) {
        let self = this;
        let index = this.props.id;
        let services = this.props.appointmentServices;

        let timeOptions = this.state.timeOptions
        let time = timeOptions.map(t => t.value)

        let cnt = 0;
        let latestTime = '';
        services.forEach(function(service, i) {
            if (service.specialist.value === option.value) {
                if (service.time.value) {
                    latestTime = {
                        'time': service.time.value,
                        'duration': service.service.duration
                    };
                }

                if (cnt === 0) {
                    cnt++
                } else if (index === i){
                    let preferredTime = moment(latestTime.time, 'hh:mm A').add(latestTime.duration, 'm').format('hh:mm A')

                    // If preferred time is available 
                    if (time.includes(preferredTime)) {
                        services[index].time = timeOptions.filter(t => t.value === preferredTime)[0];
                    } else {
                        // Else get next closest time
                        let startTime = moment(preferredTime, 'hh:mm A')
            
                        // Check against available time options
                        for (let i = 0; i < time.length; i++) {
                            let timeChecker = moment(time[i], 'hh:mm A')
                
                            if (timeChecker.isSameOrAfter(startTime)) {
                                console.log('time found ', timeChecker.format('hh:mm A'))
                                services[index].time = timeOptions.filter(t => moment(t.value, 'hh:mm A').isSame(timeChecker))[0];
                                break
                            }
                        }
                    }

                    self.setState({
                        hasSpecialist: true,
                    })
                }
            }

        })
  
        this.setState({
          appointmentServices: services,
        });
  
        console.log('services ', services)
        this.props.onUpdateAppointmentServices(services);
    }

    setAllServicesTimeOther(specialist, serviceTime) {
        let services = this.props.appointmentServices;
        let timeOptions = this.state.timeOptions
        let time = timeOptions.map(t => t.value)

        let cnt = 0;
        let newTime = '';
        let latestTime = '';
        services.forEach(function(service, i) {
            if (service.specialist.value === specialist.value) {
                if (newTime === '') {
                    newTime = serviceTime.value;
                }

                if (service.time.value) {
                    latestTime = {
                        'time': newTime,
                        'duration': service.service.duration
                    };
                }

                if (cnt === 0) {
                    services[i].time = serviceTime;
                    cnt++
                } else {
                    let preferredTime = moment(latestTime.time, 'hh:mm A').add(latestTime.duration, 'm').format('hh:mm A')
                    
                    // If preferred time is available 
                    if (time.includes(preferredTime)) {
                        services[i].time = timeOptions.filter(t => t.value === preferredTime)[0];
                    } else {
                        // Else get next closest time
                        let startTime = moment(preferredTime, 'hh:mm A')
            
                        // Check against available time options
                        for (let t = 0; t < time.length; t++) {
                            let timeChecker = moment(time[t], 'hh:mm A')
                
                            if (timeChecker.isSameOrAfter(startTime)) {
                                console.log('time found ', timeChecker.format('hh:mm A'))
                                services[i].time = timeOptions.filter(to => moment(to.value, 'hh:mm A').isSame(timeChecker))[0];
                                break
                            }
                        }
                    }

                    newTime = preferredTime;
                }
            }
        })
  
        // this.setState({
        //   appointmentServices: services,
        // });
  
        console.log('services ', services)
        // this.props.onUpdateAppointmentServices(services);
    }

    setServiceTime() {
      let index = this.props.id;
      let services = this.props.appointmentServices;
      let preferredTime = services[0].time

      let timeOptions = this.state.timeOptions
      let time = timeOptions.map(t => t.value)

      // If preferred time is available 
      if (time.includes(preferredTime.value)) {
          services[index].time = timeOptions.filter(t => t.value === preferredTime.value)[0];
      } else {
        // Get first service end time and check that on record
        let initServiceDuration = services[0].service.duration
        let newTime = moment(preferredTime.value, 'hh:mm A').add(initServiceDuration, 'm').format('hh:mm A')
        
        if (time.includes(newTime)) {
          services[index].time = timeOptions.filter(t => t.value === newTime)[0];
        } else {
          // Else get next closest time
          let startTime = moment(newTime, 'hh:mm A')

          // Check against available time options
          for (let i = 0; i < time.length; i++) {
            let timeChecker = moment(time[i], 'hh:mm A')

            if (timeChecker.isSameOrAfter(startTime)) {
              console.log('time found ', timeChecker.format('hh:mm A'))
              services[index].time = timeOptions.filter(t => moment(t.value, 'hh:mm A').isSame(timeChecker))[0];
              break
            }
          }
        }
      }

      this.setState({
        appointmentServices: services,
      });

      console.log('services ', services)
      this.props.onUpdateAppointmentServices(services);
    }

    setAllServicesTime() {
        let services = this.props.appointmentServices;
        let specialists = services.map(s => s.specialist.value);
        let preferredTime = services[0].time

        specialists.map((specialist) => {
            let cnt = 0;
            let latestTime = '';
            let newServiceTime = '';

            services.map((service, key) => {
                if (specialist === service.specialist.value) {
                    let time = service.specialist.availability

                    let timeOptions = [];
                    timeOptions = time.map((t) => ({ label: t, value: t }));

                    if (newServiceTime === '') {
                        newServiceTime = preferredTime.value;
                    }

                    if (service.time.value) {
                        latestTime = {
                            'time': newServiceTime,
                            'duration': service.service.duration
                        };
                    }

                    // If preferred time is available
                    if (cnt === 0) {
                        let newTime = timeOptions.filter(t => t.value === preferredTime.value)[0];
                        newServiceTime = newTime;
                        service.time = newTime;
                        cnt++
                    } else {
                        // Get first service end time and check that on record
                        // let initServiceDuration = services[0].service.duration
                        let newTime = moment(latestTime.time, 'hh:mm A').add(latestTime.duration, 'm').format('hh:mm A')
                        newServiceTime = newTime;

                        if (time.includes(newTime)) {
                            service.time = timeOptions.filter(t => t.value === newTime)[0];
                        } else {
                            // Else get next closest time
                            let startTime = moment(newTime, 'hh:mm A')
            
                            // Check against available time options
                            for (let i = 0; i < time.length; i++) {
                                let timeChecker = moment(time[i], 'hh:mm A')
            
                                if (timeChecker.isSameOrAfter(startTime)) {
                                    console.log('time found ', timeChecker.format('hh:mm A'))
                                    service.time = timeOptions.filter(t => moment(t.value, 'hh:mm A').isSame(timeChecker))[0];
                                    break
                                } else {
                                    service.time = []
                                }
                            }

                            newServiceTime = startTime;
                        }
                    }
                }
            })
        });


      this.setState({
        appointmentServices: services,
      });

      console.log('services ', services)
      this.props.onUpdateAppointmentServices(services);
    }

    handleTimeChange(option) {
        let index = this.props.id;
        let services = this.props.appointmentServices
        const merchant = this.props.merchant
        const merchant_business = merchant.merchant_business;
        let show_time_selection = (merchant_business)? merchant_business.show_time_selection_on_different_specialists: "";

        services[index].time = option;

        this.setState({
            appointmentServices: services
        });

        this.props.onUpdateAppointmentServices(services);

        this.getAvailableSpecialist(option.value);

        // /**
        //  * If time select is triggered either on 
        //  * 1. First service time
        //  * 2. Different specialist time
        //  * Loop through appointment services with the same specialist
        //  */
        if (!show_time_selection) {
            if (index == 0) {
                setTimeout(() => {
                    this.setAllServicesTime()
                }, 200)
            }
        } else {
            setTimeout(() => {
                let specialist = services[index].specialist;
                this.setAllServicesTimeOther(specialist, option)
            }, 200)
        }
    }

    handleTimeFocus() {
        let index = this.props.id;
        let service = this.props.appointmentServices

        if (service.length > 1 && !Array.isArray(service[index].time)) {
            this.setState({
                timeOptions: [],
                timeLoader: true,
            });

            this.getServiceAvailability(service[index].service.value, service[index].service.duration);
        }
    }

    handleServiceRowDelete(elem) {
        let index = this.props.id;
        let services = this.props.appointmentServices

        services.splice(index, 1);

        this.setState({
            appointmentServices: services
        });

        this.props.onUpdateAppointmentServices(services);
    }

    componentDidMount() {
        let index = this.props.id;
        let selectedService = this.props.appointmentServices[index];

        if (!Array.isArray(selectedService.service)) {
            this.setState({
                specialistLoader: true,
            });

            this.getServiceAvailability(selectedService.service.value, selectedService.service.duration);
        }

        if (!Array.isArray(selectedService.specialist)) {
            this.setState({
                timeLoader: true,
            });

            this.getAvailableTime(selectedService.specialist.availability);
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.appointmentDate !== prevProps.appointmentDate) {
            let index = this.props.id;
            let selectedService = this.props.appointmentServices[index];

            if (!Array.isArray(selectedService.service)) {
                this.setState({
                    specialistLoader: true,
                    timeLoader: true,
                });

                if (selectedService.service) {
                    this.getServiceAvailability(selectedService.service.value, selectedService.service.duration);
                }
            }
        }

        if (this.props.currentPage !== prevProps.currentPage) {
            let index = this.props.id;
            let selectedService = this.props.appointmentServices[index];

            if (!Array.isArray(selectedService.service)) {
                this.setState({
                    specialistLoader: true,
                    timeLoader: true,
                });

                this.getServiceAvailability(selectedService.service.value, selectedService.service.duration);
            }
        }

        if(this.props.serviceCategories !== prevProps.serviceCategories) {
            let service = this.props.serviceCategories[0].merchant_services[0];
            if(this.state.availabilityPlaceHolder.length === 0) {
                this.getServiceAvailabilityForPlaceHolder(service.id, service.estimated_duration);
            }
        }
    }

    render() {
        let state = this.state;
        let index = this.props.id;
        let selectedService = this.props.appointmentServices[index];
        let serviceOptions = [];
        let categories = this.props.serviceCategories;
        let count = this.props.count;
        let button = null;
        let servicePlaceHolder = "";
        let specialistPlaceHolder = "Select Staff";
        let timePlaceHolder = "Select Time";

        let showCategoryDescription = 0;
        const merchant_business = this.props.merchant.merchant_business;
        let show_time_selection = (merchant_business)? merchant_business.show_time_selection_on_different_specialists: "";

        if (merchant_business) {
            showCategoryDescription = merchant_business.show_service_category_description;
        }
        
        if (categories) {
            categories.forEach((category) => {
                let catDesc = (showCategoryDescription != 0 && category.description)? " - "+category.description: "";
                const services = category.merchant_services;
                const sortAlphaNum = (a, b) => a.title.localeCompare(b.title, 'en', { numeric: true })
                services.sort( sortAlphaNum );
                
                let group = category.title+catDesc;
                let serviceList = [];

                services.forEach((service) => {
                    serviceList.push({
                        label: service.title,
                        value: service.id,
                        price: service.sanitized_price,
                        duration: service.estimated_duration,
                        sort: service.sort,
                    });
                });

                serviceList.sort(function(a,b){
                    if(a.sort > b.sort){ return 1}
                    if(a.sort < b.sort){ return -1}
                        return 0;
                });

                let newOption = {
                    label: group,
                    options: serviceList
                };

                serviceOptions.push(newOption);
            });
        }


        if(serviceOptions.length > 0) {
            servicePlaceHolder = serviceOptions[0].label;
            if(state.availabilityPlaceHolder.length > 0) {
                specialistPlaceHolder = state.availabilityPlaceHolder[0].staff;
                timePlaceHolder = state.availabilityPlaceHolder[0].time;
            }
        }


        if (count > 1) {
            button = <div className={'flex__col--1 service__remove'}>
                <button className={'button button--link button--secondary'}
                        onClick={this.handleServiceRowDelete.bind(this)}><Bin className={'button__icon'}/></button>
            </div>
        }

        if (!this.state.hasSpecialist) {
            let serviceInput = 'flex__col--6';
        }

        const noTime = () => {
            const inputValue = "No available time";

            return inputValue;
        }

        const noSpecialist = () => {
            const inputValue = "No available staff";

            return inputValue;
        }

        return (
            <div className={'service__row flex flex--row flex--ai-center'}>
                <hr style={{
                    width: "100%",
                    maxwidth: "800px",
                    marginTop: "-20px",
                    marginBottom: "30px",
                    // "border-top": "0.1px solid #f70077"
                    borderTop: "0.1px solid #71b2e2"
                }} />
                <div className={'flex__col'}>
                    { !show_time_selection ?
                        <div className={'flex flex--row'}>
                            <div className={this.props.id >= 1 ? 'flex__col--6' : 'flex__col--12'}>
                                <Select
                                    options={serviceOptions}
                                    placeholder={servicePlaceHolder}
                                    isSearchable={false}
                                    onChange={this.handleServiceChange.bind(this)}
                                    value={selectedService.service}
                                    className={'form__picker'}
                                    getOptionLabel={option => this.props.merchant.show_service_price ? `${option.label} - $${option.price}` : option.label}
                                />
                            </div>
                            {
                                this.props.merchant.merchant_business ?
                                    parseInt(this.props.merchant.merchant_business.show_any_specialist) === 1 ?
                                        <React.Fragment>
                                            <div className={this.props.id >= 1 ? 'flex__col--6' : 'flex__col--6'}>
                                                <Select
                                                    options={state.timeOptions}
                                                    placeholder={timePlaceHolder}
                                                    isLoading={state.timeLoader}
                                                    isSearchable={false}
                                                    onChange={this.handleTimeChange.bind(this)}
                                                    value={selectedService.time}
                                                    className={'form__picker'}
                                                    onFocus={this.handleTimeFocus.bind(this)}
                                                    noOptionsMessage={noTime}
                                                />
                                            </div>
                                            <div className={this.props.id >= 1 ? 'flex__col--6 hidden' : 'flex__col--6'}>
                                                <Select
                                                    options={state.specialistOptions}
                                                    placeholder={specialistPlaceHolder}
                                                    isLoading={state.specialistLoader}
                                                    isSearchable={false}
                                                    onChange={this.handleSpecialistChange.bind(this)}
                                                    value={selectedService.specialist}
                                                    className={'form__picker'}
                                                    noOptionsMessage={noSpecialist}
                                                />
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className={'flex__col--6'}>
                                                <Select
                                                    options={state.specialistOptions}
                                                    placeholder={specialistPlaceHolder}
                                                    isLoading={state.specialistLoader}
                                                    isSearchable={false}
                                                    onChange={this.handleSpecialistChange.bind(this)}
                                                    value={selectedService.specialist}
                                                    className={'form__picker'}
                                                    noOptionsMessage={noSpecialist}
                                                />
                                            </div>

                                            <div className={this.props.id >= 1 ? 'flex__col--6 hidden' : 'flex__col--6'}>
                                                <Select
                                                    options={state.timeOptions}
                                                    placeholder={timePlaceHolder}
                                                    isLoading={state.timeLoader}
                                                    isSearchable={false}
                                                    onChange={this.handleTimeChange.bind(this)}
                                                    value={selectedService.time}
                                                    className={'form__picker'}
                                                    onFocus={this.handleTimeFocus.bind(this)}
                                                    noOptionsMessage={noTime}
                                                />
                                            </div>
                                        </React.Fragment>
                                    : ""
                            }
                        </div> :
                        <div className={'flex flex--row'}>
                            <div className={this.props.id >= 1 ? !this.state.hasSpecialist ? 'flex__col--12' : 'flex__col--6' : 'flex__col--12'}>
                                <Select
                                    options={serviceOptions}
                                    placeholder={servicePlaceHolder}
                                    isSearchable={false}
                                    onChange={this.handleServiceChange.bind(this)}
                                    value={selectedService.service}
                                    className={'form__picker'}
                                    getOptionLabel={option => this.props.merchant.show_service_price ? `${option.label} - $${option.price}` : option.label}
                                />
                            </div>
                            {
                                this.props.merchant.merchant_business ?
                                parseInt(this.props.merchant.merchant_business.show_any_specialist) === 1 ?
                                    <React.Fragment>
                                        <div className={this.props.id >= 1 ? 'flex__col--6' : 'flex__col--6'}>
                                            <Select
                                                options={state.timeOptions}
                                                placeholder={timePlaceHolder}
                                                isLoading={state.timeLoader}
                                                isSearchable={false}
                                                onChange={this.handleTimeChange.bind(this)}
                                                value={selectedService.time}
                                                className={'form__picker'}
                                                onFocus={this.handleTimeFocus.bind(this)}
                                                noOptionsMessage={noTime}
                                            />
                                        </div>
                                        {!this.state.hasSpecialist ?
                                            <div className={'flex__col--6'}>
                                                <Select
                                                    options={state.specialistOptions}
                                                    placeholder={specialistPlaceHolder}
                                                    isLoading={state.specialistLoader}
                                                    isSearchable={false}
                                                    onChange={this.handleSpecialistChange.bind(this)}
                                                    value={selectedService.specialist}
                                                    className={'form__picker'}
                                                    noOptionsMessage={noSpecialist}
                                                />
                                            </div> : ''
                                        }
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className={'flex__col--6'}>
                                            <Select
                                                options={state.specialistOptions}
                                                placeholder={specialistPlaceHolder}
                                                isLoading={state.specialistLoader}
                                                isSearchable={false}
                                                onChange={this.handleSpecialistChange.bind(this)}
                                                value={selectedService.specialist}
                                                className={'form__picker'}
                                                noOptionsMessage={noSpecialist}
                                            />
                                        </div>

                                        {!this.state.hasSpecialist ?
                                            <div className={'flex__col--6'}>
                                                <Select
                                                    options={state.timeOptions}
                                                    placeholder={timePlaceHolder}
                                                    isLoading={state.timeLoader}
                                                    isSearchable={false}
                                                    onChange={this.handleTimeChange.bind(this)}
                                                    value={selectedService.time}
                                                    className={'form__picker'}
                                                    onFocus={this.handleTimeFocus.bind(this)}
                                                    noOptionsMessage={noTime}
                                                />
                                            </div> : ''
                                        }
                                    </React.Fragment>
                                : ""
                            }
                        </div>
                    }
                </div>


                {button}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentServices: state.appointmentServices,
        appointmentDate: state.appointmentDate,
        currentPage: state.page
    }
};

const mapActionsToProps = {
    onUpdateAppointmentServices: updateAppointmentServices,
};

export default connect(mapStateToProps, mapActionsToProps)(AppointmentServices);