import React from 'react';


import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

import { apiRequestMerchantInfo } from '../actions/merchant-actions';
import { updatePage } from '../actions/page-actions';

import Header from '../../components/layouts/header-layout';
import Footer from '../../components/layouts/footer-layout';
import Appointment from '../appointment/Appointment';
import ErrorPage from "../pages/error-page";

import renderHTML from 'react-render-html';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      merchantName: "",
    }
  }

  componentDidMount() {
    const path = window.location.pathname
    const id = path.split('/').filter(q => q)[0]

    if (!path.includes("/appointments/")) {
      this.props.onApiRequestMerchantInfo(id ? id : 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.merchant !== prevProps.merchant) {
      if (this.props.merchant === 'error') {
        this.props.onUpdatePage('error');
      } else if (this.props.merchant.status === 0) {
        this.props.onUpdatePage('error');
      } else if (this.props.merchant.merchant_business.enable_customer_appointment_application === 0) {
        this.props.onUpdatePage('unavailable');
      } else {
        document.title = `${renderHTML(this.props.merchant.business_name)} - ${this.props.merchant.merchant_category.title} in the city`
        this.setState({
          merchantName: this.props.merchant.business_name
        })
      }
    }
  }

  render() {
    let prop = this.props

    return (
      <Router>
        <Switch>
          {/* Main Booking Page */}
          <Route path="/:alias" >
            <React.Fragment>
              <div className="page-container">
                {prop.page === 'error'
                  ? <ErrorPage />
                  : <React.Fragment>
                    <main className={'main appointment'}>
                      <h5 className={'app-header'} dangerouslySetInnerHTML={{ __html: this.state.merchantName }}></h5>
                      <div className={'container'}>
                        <Appointment currentPage={prop.page} />
                      </div>
                    </main>
                  </React.Fragment>}
              </div>
              <Footer merchant={prop.merchant} />
            </React.Fragment>
          </Route>

          <Route exact path="/" >
            <React.Fragment>
              <div className="page-container">
                {prop.page === 'error'
                  ? <ErrorPage />
                  : <React.Fragment />}
              </div>
              <Footer merchant={prop.merchant} />
            </React.Fragment>
          </Route>
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    page: state.page
  }
};

const mapActionsToProps = {
  onApiRequestMerchantInfo: apiRequestMerchantInfo,
  onUpdatePage: updatePage,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(App));
