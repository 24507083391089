import React from 'react';
import axios from 'axios';
import * as Config from '../../../utils/config';

import { connect } from 'react-redux';
import { updatePage } from '../../actions/page-actions';
import { updateAppointmentServices } from '../../actions/appointment-actions';
import Swal from 'sweetalert2'
import { Element, scroller } from 'react-scroll';

import AppointmentDate from './date/Date';
import AppointmentServices from './services/Services';
import AppointmentSummary from '../summary/Summary';


class AppointmentForm extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            summaryToggle: false
        };
    }

    handlePageChange() {
        let services = this.props.appointmentServices;
        let requiredCounter = 0;
        let defaultMessaging = 'Please fill up all service information!'

        services.forEach((service, index) => {
            if (index === 0) {
                if (service.service.length === 0 || service.specialist.length === 0 || service.time.length === 0) {
                    requiredCounter += 1;
                }
            } else {
                if (service.service.length === 0 || service.specialist.length === 0) {
                    requiredCounter += 1;
                }
            }
            
            if (index >= 1 && (service.time.length === 0 || !service.time)) { 
              requiredCounter += 1;

              let initTime = services[0].time.value
              defaultMessaging = service.specialist.label + ' is not available at ' + initTime
            }

            // if (service.specialist.value === 0) {
            //     requiredCounter +=1;
            // }
        });
        
        if (requiredCounter > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: defaultMessaging,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
            })
        } else {
            this.props.onUpdatePage('customer');
            this.setState({
                summaryToggle: true
            });

            setTimeout(() => {
                scroller.scrollTo('summary-container', {
                    duration: 800,
                    delay: 100,
                    smooth: true,
                    offset: 50,
                });
            }, 500);
        }
    }

    handleAppointmentServiceChange() {
        let services = this.props.appointmentServices;
        let requiredCounter = 0;

        services.forEach((service) => {
            if (service.service.length === 0 || service.specialist.length === 0 || service.time.length === 0) {
                requiredCounter += 1;
            }

            // if (service.specialist.value === 0) {
            //     requiredCounter +=1;
            // }
        });
        
        if (requiredCounter > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please fill up all service information!',
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
            })
        } else {
            services.push({
                service: [],
                specialist: [],
                time: []
            });
    
            this.props.onUpdateAppointmentServices(services);
        }
    }
    
    render() {
        const state = this.state;
        const props = this.props;
        const appointmentServices = props.appointmentServices;
        
        let appointmentRows = [];
        let moreButton = <button className={'button button--link'} onClick={this.handleAppointmentServiceChange.bind(this)}>+ ADD MORE</button>;

        if (appointmentServices) {
            appointmentServices.forEach((value, index) => {
                appointmentRows.push(<AppointmentServices count={appointmentServices.length} serviceCategories={props.merchant.service_categories} 
                    specialists={props.merchant.specialists} appointmentDate={props.appointmentDate} id={index} key={index} />);
            })
        } else {
            appointmentRows = "";
        }

        if (appointmentServices.length >= 5) {
            moreButton = "";
        }

        return(
            <React.Fragment>
                {/* <h5 className={'main__title text--center'}>book your appointment</h5> */}
                <Element name="service-container">
                    <div className={'appointment__form'}>
                        <AppointmentDate holidays={props.merchant.holiday_entries} business={props.merchant.merchant_business}/>

                        <div className={'appointment__form__services'}>
                            {/* <h4 className={'appointment__form__title text--center'}>select services</h4> */}

                            <div className={'service'}>
                                {/* <div className={'service__header flex flex--row'}>
                                    <h6 className={'flex__col--4'}>service</h6>
                                    <h6 className={'flex__col--4'}>specialist</h6>
                                    <h6 className={'flex__col--3'}>time</h6>
                                </div> */}

                                {appointmentRows}

                                <div className={'text--center'}>
                                    {moreButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
                
                <div className={'text--center'}>
                    {!state.summaryToggle ? <button className={'button button--bg'} onClick={this.handlePageChange.bind(this)}>Continue Booking</button> : ''}
                    
                </div>
                            
                <Element name="summary-container">
                    {state.summaryToggle ? <AppointmentSummary /> : ''}
                </Element>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentDate: state.appointmentDate,
        appointmentServices: state.appointmentServices
    }
};

const mapActionsToProps = {
    onUpdatePage: updatePage,
    onUpdateAppointmentServices: updateAppointmentServices,
};

export default connect(mapStateToProps, mapActionsToProps)(AppointmentForm);