import React from 'react';
import { Link, withRouter } from "react-router-dom";

class CustomerCount extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      customerCount: 0
    }
  }

  handleNextClick = () => {
    const data = {
      step: 'stepFour',
      stepActive: 4,
    }

    this.props.handleNext(data)
  }

  handlePrevClick = () => {
    const data = {
      step: 'stepTwo',
      stepActive: 2,
    }

    this.props.handlePrev(data)
  }

  render() {
    const props = this.props
    const alias = props.match.params.alias

    return (
      <React.Fragment>
        <div className='customer__count-container'>
          <p className='customer__count__title'>How many in your party?</p>

          <div className='customer__count__body'>
            <div className="input-group justify-content-center">
              <div className="input-group-prepend">
                <button 
                  disabled={props.customerCount === 0}
                  className="btn btn-secondary customer__button customer__button-subtract" 
                  type="button" onClick={props.subtractCustomerCount}>
                  <span>-</span>
                </button>
              </div>

              <input 
                type="number" 
                aria-label="0"
                placeholder="0" 
                className="form-control customer__count-input"
                value={props.customerCount}
                readOnly
              />

              <div className="input-group-append">
                <button className="btn btn-primary customer__button customer__button-add" type="button" onClick={props.addCustomerCount}>
                  <span>+</span>
                </button>
              </div>
            </div>

            <div className='d-flex justify-content-between pt-4'>
              <Link to={`/${alias}/specialists`}>
                <button className="btn btn-secondary back" onClick={this.handlePrevClick}>Back</button>
              </Link>

              <Link to={`/${alias}/date`}>
                <button
                  className={props.customerCount > 0? 
                    "btn btn-primary next": 
                    "btn btn-primary next disabled"
                  } 
                  onClick={this.handleNextClick}
                >Next</button>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerCount);