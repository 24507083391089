import React from "react";

import Logo from '../../assets/images/logo.png';

function Footer({ merchant }) {
  return (
    <footer className="footer">
      {merchant && merchant.show_platform_info 
        ? <div className="d-flex justify-content-center">
        Powered By <img src={Logo} alt="" />
      </div> : '' }
    </footer>
  )
}

export default Footer;