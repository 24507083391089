import { UPDATE_SERVICES, UPDATE_SERVICE_SPECIALIST } from '../actions/appointment-actions';

function appointmentServicesReducer (state = [], {type, payload}) {
  switch (type) {
    case UPDATE_SERVICES:
      return [
        ...state
      ]
    case UPDATE_SERVICE_SPECIALIST: 
      if (state.length) {
        state[0].specialist = payload
        console.log('new data', payload)
      }

      return state
    default:
      return state;
  }
}

export default appointmentServicesReducer;
