import React from 'react';
import {connect} from 'react-redux';
import Calendar from 'react-calendar';

import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

import {updateAppointmentServices} from '../../actions/appointment-actions';

import DateSlider from './calendar/DateSlider';
import TimeSelect from './calendar/TimeSelect';
import Loader from "../../layouts/loader";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";

class DateTime extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            date: new Date(),
            showCalendar: false
        }

        this.showHideCalendar = this.showHideCalendar.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    handleNextClick = () => {
        const data = {
            step: 'stepFive',
            stepActive: 5,
        }

        this.props.handleNext(data)
    }

    handlePrevClick = () => {
        const data = {
            step: 'stepTwo',
            stepActive: 2,
        }

        this.props.handlePrev(data)
    }

    showHideCalendar() {
        let calendar = this.state.showCalendar
        calendar = (calendar) ? false : true;

        this.setState({
            showCalendar: calendar
        });
    }

    handleDateChange(day) {
        this.props.handleDateClick(day);
    }

    render() {
        const props = this.props
        const alias = props.match.params.alias

        const merchant = props.merchant
        const businessSettings = merchant.merchant_business

        // const timeZone = businessSettings.merchant_timezone.application_value
        // moment.tz.setDefault(timeZone)

        const tzToday = moment().format('YYYY/MM/DD')
        let tzSelected = moment().format('YYYY/MM/DD')

        if (props.selectedDate) {
            tzSelected = moment(props.selectedDate).format('YYYY/MM/DD')
            console.log('tzSelected', tzSelected)
        }

        const today = new Date(tzToday)
        const selectedDate = new Date(tzSelected)

        const nextLabel = <FontAwesomeIcon icon={faChevronRight}/>
        const prevLabel = <FontAwesomeIcon icon={faChevronLeft}/>

        const minDate = new Date(moment(props.appointmentStartDate).format('YYYY/MM/DD'))
        const maxDate = new Date(moment(props.dateSlides[props.dateSlides.length - 1]).format('YYYY/MM/DD'))


        return (
            <React.Fragment>
                <div className='calendar-container'>
                    <p className="calendar__title mx-auto mb-5">Select date and time</p>

                    <div className='calendar__body'>
                        <div className={'calendar__inline mx-auto'}>
                            <div className='d-flex justify-content-between'>
                                <label className={'label m-3'}><strong>Date</strong></label>
                                <button
                                    className={'btn btn-outline-dark calendar__button-small calendar__button-rounded m-3'}
                                    onClick={this.showHideCalendar}>Show Calendar
                                </button>
                            </div>

                            {this.state.showCalendar ?
                                <Calendar
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    next2Label={null}
                                    prev2Label={null}
                                    defaultValue={today}
                                    className={'mx-auto'}
                                    nextLabel={nextLabel}
                                    prevLabel={prevLabel}
                                    onChange={this.handleDateChange}
                                    value={selectedDate}
                                    navigationLabel={({date}) => `${moment(date).format('MMM YYYY')}`}
                                    tileDisabled={(({date}) => props.disabledDates.includes(moment(date).format('YYYY-MM-DD')))}
                                /> : ''
                            }
                        </div>
                    </div>

                    <div className={'mx-auto'}>
                        <DateSlider
                            date={props.date}
                            merchant={props.merchant}
                            dateSlides={props.dateSlides}
                            currentSlide={props.currentSlide}
                            selectedDate={props.selectedDate}
                            showCalendar={props.showCalendar}
                            handleDateClick={props.handleDateClick}
                            appointmentServices={props.appointmentServices}
                            windowWidth={props.windowWidth}
                        />
                    </div>

                    <div className={'mx-auto'}>
                        {this.props.loader.componentLoading
                            ? <Loader/>
                            : <TimeSelect
                                time={props.time}
                                merchant={props.merchant}
                                selectedTime={props.selectedTime}
                                selectedDate={props.selectedDate}
                                timeCollection={props.timeCollection}
                                handleTimeClick={props.handleTimeClick}
                                appointmentServices={props.appointmentServices}

                                ts={new Date().getTime()}
                            />}
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Link to={`/${alias}/specialists`}>
                            <button className="btn btn-secondary back" onClick={this.handlePrevClick}>Back</button>
                        </Link>

                        <Link to={`/${alias}/customer`}
                              className={props.selectedTime && props.selectedDate ? '' : 'disabled'}>
                            <button className={props.selectedTime && props.selectedDate ?
                                "btn btn-primary next" : "btn btn-primary next disabled"
                            }
                                    onClick={this.handleNextClick}
                            >Next
                            </button>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentServices: state.appointmentServices,
        loader: state.loader
    }
};

const mapActionsToProps = {
    onUpdateAppointmentServices: updateAppointmentServices
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(DateTime));