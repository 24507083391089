import {times} from 'lodash';
import moment from 'moment';
import React from 'react';
import {connect} from "react-redux";

class TimeSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedService: null,
            selectedSpecialist: null,
            selectedDate: null,
            timeOptions: [],
            showMorning: false,
            showAfternoon: false,
            showEvening: false,
        }

        this.showMore = this.showMore.bind(this)
        this.isActive = this.isActive.bind(this)
    }

    showMore(selector) {
        const ancestor = document.getElementById(selector);
        let descendants = ancestor.getElementsByTagName('li');

        let i, descendant;
        for (i = 0; i < descendants.length; i++) {
            descendant = descendants[i];
            if (descendant.classList.contains('d-none')) {
                descendant.classList.remove('d-none');
            }
        }

        const btnSelector = '.' + selector;
        const showMoreBtn = document.querySelector(btnSelector);

        showMoreBtn.classList.add('d-none');
        switch (selector) {
            case "afternoon__time":
                this.setState({
                    showAfternoon: true,
                });
                break;
            case "evening__time":
                this.setState({
                    showEvening: true,
                });
                break;
            default:
                this.setState({
                    showMorning: true,
                });
        }
    }

    timeClass(timeSlot, maxCount, showTime, isActive = false) {
        if (showTime) {
            return this.isActive(isActive)
        } else {
            if (timeSlot > maxCount) {
                return this.isActive(isActive, true)
            } else {
                return this.isActive(isActive)
            }
        }
    }

    isActive(isActive, hiddenClass = false) {
        hiddenClass = hiddenClass ? "d-none" : "";

        let slotClass = "";
        if (isActive) {
            slotClass = "calendar__times__slot-item calendar__times__slot-active " + hiddenClass
        } else {
            slotClass = "calendar__times__slot-item " + hiddenClass;
        }

        return slotClass;
    }

    render() {
        const props = this.props
        const selectedDate = props.selectedDate
        const selectedTime = props.selectedTime
        const showMorning = this.state.showMorning;
        const showAfternoon = this.state.showAfternoon;
        const showEvening = this.state.showEvening;

        let specialist = this.props.appointmentServices[0].specialist

        const timeCollection = specialist.availability

        const merchant = props.merchant
        const businessSettings = merchant.merchant_business

        // const timeZone = businessSettings.merchant_timezone.application_value
        // moment.tz.setDefault(timeZone)

        const currentDate = moment().format('YYYY-MM-DD')
        const currentTime = moment().add(0, 's')

        const day = moment(selectedDate).format('dddd');
        const operationFrom = businessSettings["operation_" + day.toLowerCase() + "_from"]
        const operationTo = businessSettings["reservation_cutoff_" + day.toLowerCase()]

        let holidayEntryTimes = [];
        if (merchant.holiday_entries) {
            merchant.holiday_entries.map((holiday) => {
                if (holiday.has_hours) {
                    if (holiday.holiday_date === holiday.holiday_date_to) {
                        if (holiday.holiday_date_to === selectedDate) {
                            holidayEntryTimes = holiday.times;
                        }
                    } else {
                        const holidayStartDate = moment(holiday.holiday_date + " 00:00:00");
                        const holidayEndDate = moment(holiday.holiday_date_to + " 23:59:59");
                        const checkDate = moment(selectedDate + " 00:01:00");
                        if (checkDate.isBetween(holidayStartDate, holidayEndDate)) {
                            holidayEntryTimes = holiday.times;
                        }
                    }
                }
            })
        }

        let timesToRemoved = [];
        if (holidayEntryTimes.length > 0) {
            holidayEntryTimes.map((time) => {
                timesToRemoved.push({
                    start: time.start_time,
                    end: time.end_time
                })
            });
        }

        console.log(`this is the times to removed`)
        console.log(holidayEntryTimes)
        console.log(timesToRemoved)
        console.log(`this is the times to removed end`)
        const newOperationFrom = moment(operationFrom, 'hh:mm A')
        const newOperationTo = moment(operationTo, 'hh:mm A')

        let morningTime = []
        let afternoonTime = []
        let eveningTime = []

        if (timeCollection.length) {
            const maxTimeCount = 8;
            let morningTimeCount = 0;
            let afternoonTimeCount = 0;
            let eveningTimeCount = 0;
            timeCollection.map((time, index) => {
                let timeHere = [];
                if (timesToRemoved.length > 0) {
                    timesToRemoved.map((remove) => {
                        const removeStartTime = moment(remove.start, 'hh:mm A');
                        const removeEndTime = moment(remove.end, 'hh:mm A');
                        const timeToShow = moment(time, 'hh:mm A');
                        if (removeStartTime <= timeToShow && timeToShow < removeEndTime) {
                            timeHere.push(timeToShow)
                        }
                    })
                }
                if (currentDate === selectedDate) {
                    if (currentTime < moment(time, 'hh:mm A')) {
                        if (newOperationFrom <= moment(time, 'hh:mm A') && moment(time, 'hh:mm A') <= newOperationTo) {
                            if (timeHere.length === 0) {
                                if (moment(time, 'hh:mm A').isSameOrAfter(moment('12:00 AM', 'hh:mm A'))
                                    && moment(time, 'hh:mm A').isBefore(moment('12:00 PM', 'hh:mm A'))) {
                                    const timeItem = <li
                                        id={time}
                                        key={index}
                                        data-index={moment(time, 'hh:mm A').format('HH:mm')}
                                        className={moment(time, 'hh:mm A').format('HH:mm') === selectedTime ?
                                            this.timeClass(morningTimeCount, maxTimeCount, showMorning, true) :
                                            this.timeClass(morningTimeCount, maxTimeCount, showMorning)}
                                        onClick={props.handleTimeClick}
                                    >
                                        <p data-index={moment(time, 'hh:mm A').format('HH:mm')}>{moment(time, 'hh:mm A').format('hh:mm A')}</p>
                                    </li>

                                    morningTime.push(timeItem)
                                    morningTimeCount += 1
                                } else if (moment(time, 'hh:mm A').isSameOrAfter(moment('12:00 PM', 'hh:mm A'))
                                    && moment(time, 'hh:mm A').isBefore(moment('06:00 PM', 'hh:mm A'))) {
                                    const timeItem = <li
                                        id={time}
                                        key={index}
                                        data-index={moment(time, 'hh:mm A').format('HH:mm')}
                                        className={moment(time, 'hh:mm A').format('HH:mm') === selectedTime ?
                                            this.timeClass(afternoonTimeCount, maxTimeCount, showAfternoon, true) :
                                            this.timeClass(afternoonTimeCount, maxTimeCount, showAfternoon)}
                                        onClick={props.handleTimeClick}
                                    >
                                        <p data-index={moment(time, 'hh:mm A').format('HH:mm')}>{moment(time, 'hh:mm A').format('hh:mm A')}</p>
                                    </li>

                                    afternoonTime.push(timeItem)
                                    afternoonTimeCount += 1
                                } else if (moment(time, 'hh:mm A').isSameOrAfter(moment('06:00 PM', 'hh:mm A'))
                                    && moment(time, 'hh:mm A').isBefore(moment('11:59 PM', 'hh:mm A'))) {
                                    const timeItem = <li
                                        id={time}
                                        key={index}
                                        data-index={moment(time, 'hh:mm A').format('HH:mm')}
                                        className={moment(time, 'hh:mm A').format('HH:mm') === selectedTime ?
                                            this.timeClass(eveningTimeCount, maxTimeCount, showEvening, true) :
                                            this.timeClass(eveningTimeCount, maxTimeCount, showEvening)}
                                        onClick={props.handleTimeClick}
                                    >
                                        <p data-index={moment(time, 'hh:mm A').format('HH:mm')}>{moment(time, 'hh:mm A').format('hh:mm A')}</p>
                                    </li>

                                    eveningTime.push(timeItem)
                                    eveningTimeCount += 1
                                }
                            }
                        }
                    }
                } else {
                    if (newOperationFrom <= moment(time, 'hh:mm A') && moment(time, 'hh:mm A') <= newOperationTo) {


                        if (timeHere.length === 0) {

                            if (moment(time, 'hh:mm A').isSameOrAfter(moment('12:00 AM', 'hh:mm A'))
                                && moment(time, 'hh:mm A').isBefore(moment('12:00 PM', 'hh:mm A'))) {

                                const timeItem = <li
                                    id={time}
                                    key={index}
                                    data-index={moment(time, 'hh:mm A').format('HH:mm')}
                                    className={moment(time, 'hh:mm A').format('HH:mm') === selectedTime ?
                                        this.timeClass(morningTimeCount, maxTimeCount, showMorning, true) :
                                        this.timeClass(morningTimeCount, maxTimeCount, showMorning)}
                                    onClick={props.handleTimeClick}
                                >
                                    <p data-index={moment(time, 'hh:mm A').format('HH:mm')}>{moment(time, 'hh:mm A').format('hh:mm A')}</p>
                                </li>

                                morningTime.push(timeItem)
                                morningTimeCount += 1

                            } else if (moment(time, 'hh:mm A').isSameOrAfter(moment('12:00 PM', 'hh:mm A'))
                                && moment(time, 'hh:mm A').isBefore(moment('06:00 PM', 'hh:mm A'))) {
                                const timeItem = <li
                                    id={time}
                                    key={index}
                                    data-index={moment(time, 'hh:mm A').format('HH:mm')}
                                    className={moment(time, 'hh:mm A').format('HH:mm') === selectedTime ?
                                        this.timeClass(afternoonTimeCount, maxTimeCount, showAfternoon, true) :
                                        this.timeClass(afternoonTimeCount, maxTimeCount, showAfternoon)}
                                    onClick={props.handleTimeClick}
                                >
                                    <p data-index={moment(time, 'hh:mm A').format('HH:mm')}>{moment(time, 'hh:mm A').format('hh:mm A')}</p>
                                </li>

                                afternoonTime.push(timeItem)
                                afternoonTimeCount += 1
                            } else if (moment(time, 'hh:mm A').isSameOrAfter(moment('06:00 PM', 'hh:mm A'))
                                && moment(time, 'hh:mm A').isBefore(moment('11:59 PM', 'hh:mm A'))) {
                                const timeItem = <li
                                    id={time}
                                    key={index}
                                    data-index={moment(time, 'hh:mm A').format('HH:mm')}
                                    className={moment(time, 'hh:mm A').format('HH:mm') === selectedTime ?
                                        this.timeClass(eveningTimeCount, maxTimeCount, showEvening, true) :
                                        this.timeClass(eveningTimeCount, maxTimeCount, showEvening)}
                                    onClick={props.handleTimeClick}
                                >
                                    <p data-index={moment(time, 'hh:mm A').format('HH:mm')}>{moment(time, 'hh:mm A').format('hh:mm A')}</p>
                                </li>

                                eveningTime.push(timeItem)
                                eveningTimeCount += 1
                            }

                        }


                    }
                }

            })
        }

        return (
            <React.Fragment>
                <div className="calendar__times my-3">
                    {selectedDate
                        ?
                        <div className="">
                            {!morningTime.length
                            && !afternoonTime.length
                            && !eveningTime.length
                                ? <div className="calendar__times__label--noData">no available time slot</div>
                                : ''}

                            {morningTime.length ? <div>
                                <h3 className="calendar__times__label">Morning</h3>
                                <ul id="morning__time" className="calendar__times__slot mx-auto">
                                    {morningTime}
                                </ul>

                                <div className="text-center">
                                    {morningTime.length > 9 ?
                                        <button
                                            className='btn btn-light morning__time'
                                            onClick={() => this.showMore('morning__time')}
                                        >
                                            Show More
                                        </button> : ''}
                                </div>
                            </div> : ''}

                            {afternoonTime.length ? <div>
                                <h3 className="calendar__times__label">Afternoon</h3>
                                <ul id="afternoon__time" className="calendar__times__slot mx-auto">
                                    {afternoonTime}
                                </ul>

                                <div className="text-center">
                                    {afternoonTime.length > 9 ?
                                        <button
                                            className='btn btn-light afternoon__time'
                                            onClick={() => this.showMore('afternoon__time')}
                                        >
                                            Show More
                                        </button> : ''}
                                </div>
                            </div> : ''}

                            {eveningTime.length ? <div>
                                <h3 className="calendar__times__label">Evening</h3>
                                <ul id="evening__time" className="calendar__times__slot mx-auto">
                                    {eveningTime}
                                </ul>


                                <div className="text-center">
                                    {eveningTime.length > 9 ?
                                        <button
                                            className='btn btn-light evening__time'
                                            onClick={() => this.showMore('evening__time')}
                                        >
                                            Show More
                                        </button> : ''}
                                </div>
                            </div> : ''}
                        </div>
                        : <div className="calendar__times__label--noData">no date selected</div>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentServices: state.appointmentServices,
        availableSpecialists: state.availableSpecialists
    }
}

export default connect(mapStateToProps, [])(TimeSelect);