export const UPDATE_PAGE_LOADING = 'page:updateLoading';
export const UPDATE_COMPONENT_LOADING = 'component:updateLoading';

export function updatePageLoading(isLoading) {
  return {
    type: UPDATE_PAGE_LOADING,
    payload: isLoading
  }
}

export function updateComponentLoading(isLoading) {
  return {
    type: UPDATE_COMPONENT_LOADING,
    payload: isLoading
  }
}
