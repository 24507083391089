import React from 'react';

import {connect} from 'react-redux';
import {updateAppointmentDate, updateAppointmentServices} from '../../../actions/appointment-actions';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import "moment-timezone";

class AppointmentDate extends React.Component {

    handleDayChange(day) {
        let services = this.props.appointmentServices;

        if (services[0].service !== null || services[0].service.length !== 0) {
            services.forEach((service, index) => {
                service.service = []
                service.specialist = [];
                service.time = [];
            });

            this.props.onUpdateAppointmentServices(services);
        }

        //moment.tz.setDefault(this.props.business.merchant_timezone.application_value)
        //console.log(moment(day).format('MMMM D YYYY'));

        this.props.onDayUpdate(moment.utc(day).format('MMMM D YYYY'));
    }

    handlePickerPress(e) {
        e.preventDefault();

        return false;
    }

    render() {
        let currentDay = moment().format('MMMM D YYYY');
        const maxMonth = moment().add(2, 'month').format('MMMM D YYYY');
        const selectedDay = this.props.appointmentDate.length !== 0 ? this.props.appointmentDate : "";
        const business = this.props.business;

        const holidayEntries = this.props.holidays;
        let disabledDays = [];
        let onlineAppointmentStart = {};
        let disableDates = [];

        if (business) {

            if (!business.regular_sunday) {
                disabledDays.push(0);
            }

            if (!business.regular_monday) {
                disabledDays.push(1);
            }

            if (!business.regular_tuesday) {
                disabledDays.push(2);
            }

            if (!business.regular_wednesday) {
                disabledDays.push(3);
            }

            if (!business.regular_thursday) {
                disabledDays.push(4);
            }

            if (!business.regular_friday) {
                disabledDays.push(5);
            }

            if (!business.regular_saturday) {
                disabledDays.push(6);
            }

            console.log(moment(business.online_appointment_start).toDate());
            if (business.online_appointment_start) {
                onlineAppointmentStart = {
                    before: moment(business.online_appointment_start).toDate()
                };
            }

            if (business.days_before_booking) {
                currentDay = moment().add(business.days_before_booking, "days").format("MMMM D YYYY");
            }

            //holidays
            if (holidayEntries) {
                for (let counter = 0; counter < holidayEntries.length; counter++) {
                    let holidayFrom = holidayEntries[counter].holiday_date;
                    let holidayTo = holidayEntries[counter].holiday_date_to;
                    if(parseInt(holidayEntries[counter].has_hours) === 0) {
                        if(holidayTo) {
                            disableDates.push({
                                from: moment(holidayFrom).toDate(),
                                to: moment(holidayTo).toDate()
                            })
                        } else {
                            disableDates.push(moment(holidayFrom).toDate())
                        }
                    }
                }
            }

            //previous date
            disableDates.push({
                before: moment(currentDay).toDate()
            });
            //week
            disableDates.push({
                daysOfWeek: disabledDays
            });
            //online appointments
            disableDates.push(onlineAppointmentStart);

            if(parseInt(business.schedule_limitation) !== 0) {
                let fromDateLimitation = moment(currentDay).add(1, "days").toDate();
                let toDateLimitation = moment(currentDay).add(business.schedule_limitation, "days").toDate();
                console.log(moment(currentDay).format("MMMM D YYYY") + " ===== " + moment(currentDay).add(business.schedule_limitation, "days").format("MMMM D YYYYY"));
                disableDates.push({
                    before: fromDateLimitation,
                    after: toDateLimitation
                });
            }


        }

        return (
            <div className={'appointment__form__date text--center'}>
                {/* <h4 className={'appointment__form__title'}>appointment date</h4> */}

                <DayPickerInput
                    onDayChange={this.handleDayChange.bind(this)}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format={'MMMM D YYYY'}
                    value={selectedDay}
                    placeholder={'Select Date'}
                    inputProps={{
                        className: 'form__input',
                        id: 'date-picker',
                        onKeyDown: (e => this.handlePickerPress(e)),
                        readOnly: 'readonly'
                    }}
                    dayPickerProps={{
                        fromMonth: new Date(),
                        disabledDays: disableDates,
                        toMonth: new Date(maxMonth)
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appointmentDate: state.appointmentDate,
        appointmentServices: state.appointmentServices,
    }
};

const mapActionsToProps = {
    onDayUpdate: updateAppointmentDate,
    onUpdateAppointmentServices: updateAppointmentServices,
};

export default connect(mapStateToProps, mapActionsToProps)(AppointmentDate);