import { UPDATE_INFO } from '../actions/customer-actions';

function customerReducer (state = [], {type, payload}) {
  switch (type) {
    case UPDATE_INFO:
      return {
        name: payload.customerInformation.name,
        email: payload.customerInformation.email,
        mobileNumber: payload.customerInformation.mobileNumber,
        birthDate: payload.customerInformation.birthDate,
        notes: payload.customerInformation.notes,
        gender: payload.customerInformation.gender
      };
    default:
      return state;
  }
}

export default customerReducer;
