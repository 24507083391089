import axios from 'axios';
import * as Config from "../../utils/config";
import { updatePageLoading } from "./page-loader-actions";

export const GET_MERCHANT_INFO = 'merchant:getMerchantInfo';

export function getMerchantInfo(merchant) {
  return {
    type: GET_MERCHANT_INFO,
    payload: {
      merchant: merchant
    }
  }
}

export function apiRequestMerchantInfo(merchantID) {
  return dispatch => {
    axios.get(`${Config.API_URL}/merchants/${merchantID}`, {
      headers: {
        'Authorization': Config.TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        const merchant = response.data;

        dispatch(getMerchantInfo(merchant.internalMessage));
        dispatch(updatePageLoading(false))
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMerchantInfo('error'));
      })
  }
}
