import React, {useEffect} from 'react';
import Summary from '../appointment/Summary';
import AppointmentDetails from '../appointment/AppointmentDetails';
import ErrorPage from '../pages/error-page';
import HolidayPage from '../pages/holiday-entries';
import ServiceUnavailablePage from '../pages/service-unavailable';

const Appointment = (props) => {
  let currentPage = props.currentPage;
  let view = "";

  useEffect(() => {
    if(performance.navigation) {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        const path = window.location.pathname
        const segment = path.split('/').filter(q => q);
        const alias = segment[0]

        if (segment.length > 1) {
          if (segment[1] !== "summary") {
            window.location.href = '/'+alias
          }
        }
      }
    }
  });

  if (currentPage === "summary") {
    view = <Summary />
  } else {
    view = <AppointmentDetails />
  }

  if (currentPage === "error") {
    view = <ErrorPage />
  }

  if (currentPage === "unavailable") {
    view = <ServiceUnavailablePage />
  }

  if (currentPage === "holiday") {
    view = <HolidayPage />
  }

  return (
    <React.Fragment>
      {view}
    </React.Fragment>
  );
}

export default Appointment;