import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

const ServiceUnavailable = (props) => {
  const [number, setNumber] = useState('')

  useEffect(() => {
    if (props.merchant) {
      setNumber(props.merchant.phone)
    }
  }, [props.merchant])

  return (
    <div className={'unavailable'}>
      <div className={'text-center'}>
          <h2 style={{ fontWeight: '400' }}>
            Thank you for visiting! </h2>

          <br/>

          <h3 style={{ fontWeight: '300' }}>
            Unfortunately, there are no available slots at this time. <br/>
            Please contact us at {number} so we can help you get an appointment or answer your inquiries. </h3>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      merchant: state.merchant,
  }
};

export default connect(mapStateToProps)(ServiceUnavailable);