import React, { useRef } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { useIsVisible } from 'react-is-visible'

const DateSlider = (props) => {
  const dateSlides = props.dateSlides
  const dateSliderLimit = props.dateSlides.length

  let slidesVisible = props.windowWidth <= 779 ? 4 : 7
  let steps = slidesVisible

  if (dateSliderLimit < 7) {
    slidesVisible = dateSliderLimit
    steps = 1
  }

  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef)

  return (
    <React.Fragment>
      <CarouselProvider
        totalSlides={dateSlides.length}
        dragEnabled={false}
        touchEnabled={false}
        infinite={false}
        visibleSlides={slidesVisible}
        step={steps}
        naturalSlideWidth={10}
        naturalSlideHeight={90}
        className={"calendar__slider pt-3"}
        currentSlide={props.currentSlide}
      >
        <ButtonBack className='calendar__slider__prev'><FontAwesomeIcon icon={faChevronLeft} /></ButtonBack>
        <Slider className='calendar__slider__days'>
          {dateSlides.map((date, index) => {
            if (index === dateSliderLimit) {
              return <Slide
                id={date}
                key={index}
                index={index}
                data-slide-index={index}
                data-index={date}
                className={props.selectedDate ?
                  props.selectedDate === date ? "calendar__slider__days-item calendar__slider__days-active" : "calendar__slider__days-item"
                  : "calendar__slider__days-item"
                }
                onClick={props.handleDateClick}
              >
                <p className="mb-0" data-index={date} ref={nodeRef}>{moment(date).format('ddd')}</p>
                <p className="mb-0" data-index={date}>{moment(date).format('DD')}</p>
                <p className="mb-0" data-index={date}>{moment(date).format('MMM')}</p>
              </Slide>
            } else {
              return <Slide
                id={date}
                key={index}
                index={index}
                data-slide-index={index}
                data-index={date}
                className={props.selectedDate ?
                  props.selectedDate === date ? "calendar__slider__days-item calendar__slider__days-active" : "calendar__slider__days-item"
                  : "calendar__slider__days-item"
                }
                onClick={props.handleDateClick}
              >
                <p className="mb-0" data-index={date}>{moment(date).format('ddd')}</p>
                <p className="mb-0" data-index={date}>{moment(date).format('DD')}</p>
                <p className="mb-0" data-index={date}>{moment(date).format('MMM')}</p>
              </Slide>
            }
          })
        }
        </Slider>
        <ButtonNext className='calendar__slider__next' disabled={isVisible}><FontAwesomeIcon icon={faChevronRight} /></ButtonNext>
      </CarouselProvider>
    </React.Fragment>
  );
}

export default DateSlider;