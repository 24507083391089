export const UPDATE_PAGE = 'page:updatePage';

export function updatePage(page) {
  return {
    type: UPDATE_PAGE,
    payload: {
      page: page
    }
  }
}
