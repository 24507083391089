import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { updateAppointmentServices } from '../../actions/appointment-actions';

class Service extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDown: false,
      scrollLeft: "",
      startX: "",
    }

    // this.handleMouseMove = this.handleMouseMove.bind(this);
    // this.handleMouseDown = this.handleMouseDown.bind(this);
    // this.handleMouseleave = this.handleMouseleave.bind(this);
  }

  handleNextClick = () => {
    const data = {
      step: 'stepTwo',
      stepActive: 2,
    }

    this.props.handleNext(data)
  }

  // handleMouseDown(e) {
  //   const slider = document.getElementById('category__slider');

  //   slider.classList.add('active');
  //   this.setState({
  //     isDown: true,
  //     startX: e.pageX - slider.offsetLeft,
  //     scrollLeft: slider.scrollLeft
  //   })
  // }

  // handleMouseleave() {
  //   const slider = document.getElementById('category__slider');

  //   slider.classList.remove('active');
  //   this.setState({
  //     isDown: false
  //   })
  // }

  // handleMouseMove(e) {
  //   const slider = document.getElementById('category__slider');
  //   const { 
  //       isDown, 
  //       startX, 
  //       scrollLeft 
  //     } = this.state

  //   if(!isDown) return;
  //   e.preventDefault();
  //   const x = e.pageX - slider.offsetLeft;
  //   const walk = (x - startX) * 1;
  //   slider.scrollLeft = scrollLeft - walk;
  // }

  categories() {
    const props = this.props;
    const categories = props.categories;

    if (categories) {
      if (categories.lenght > 7) {
        return <ul
          id="category__slider"
          className="category__list"
        // onMouseDown={this.handleMouseDown}
        // onMouseLeave={this.handleMouseleave}
        // onMouseUp={this.handleMouseleave}
        // onMouseMove={this.handleMouseMove}
        >
          {categories.map((category, index) => {
            <li key={index} className="category__item">
              <a id={'tab' + index} className="category__link" data-index={category.id} href={'#' + category.id}>{category.title}</a>
              <div className={props.tabActive !== 'tab' + index ? 'category__link__active-indicator d-none' : 'category__link__active-indicator'}></div>
            </li>
          })
          }
        </ul>
      }
    }
  }

  categoryServices() {
    const props = this.props;
    const categories = props.categories;

    console.log(props.merchant.merchant_business);

    return categories.map((category, index) => {
      if (category.merchant_services.length > 0) {
        return <div className="row mb-4" key={index} id={category.id}>
          <p className="category__title">{category.title}</p>
          {category.merchant_services.map((service, index) => {
            return <div key={index} className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
              <div
                id={service.id}
                data-index={service.id}
                className={props.selectedService
                  ? service.id === props.selectedService.id
                    ? "category__service-item category__service-active"
                    : "category__service-item"
                  : "category__service-item"}
                onClick={props.handleServiceClick}
              >
                  <p className="category__service-title" data-index={service.id}>
                    {service.title}
                    <span className="category__service-est" data-index={service.id}>
                      {
                        props.merchant.merchant_business.hide_show_service_duration ?
                          <span data-index={service.id}>{service.estimated_duration + ' mins'}</span>
                        : ''
                      }
                    </span>
                  </p>
                <p className="category__service-price" data-index={service.id}>
                  {props.merchant.show_service_price == 1
                    ? '$' + service.sanitized_price : ''}
                </p>
              </div>
            </div>
          }
          )}
        </div>
      }
    })
  }

  categoryChange(e) {
    const element = document.getElementById(e.target.value);
    const elementOffset = element.getBoundingClientRect().top + window.pageYOffset + (-270);
    // element.scrollIntoView();
    window.scrollTo({ top: elementOffset, behavior: 'smooth' });
  }

  render() {
    const props = this.props;
    const categories = props.categories;
    const alias = props.alias
    
    return (
      <React.Fragment>
        {/* <div className="container pt-3 category">
          {this.categories()}
        </div> */}

        <div className='category-container'>
          <div className='category-container__select'>
            <select className='form-select mb-3' onChange={this.categoryChange}>
              {categories.map((category, index) => {
                if (category.merchant_services.length > 0) {
                  return <option key={index} data-index={category.id} value={category.id}>{category.title}</option>
                }
              }
              )}
            </select>

            <div className='action-button'>
              <div className=''>
                <Link to={`/${alias}/specialists`} className={props.selectedService ? '' : 'disabled'}>
                  <button
                    className={props.selectedService ?
                      "btn btn-primary next" :
                      "btn btn-primary next disabled"
                    }
                    onClick={this.handleNextClick}
                  >Next</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="category-container__body">
            {categories ? this.categoryServices() : ''}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    appointmentDate: state.appointmentDate,
    appointmentServices: state.appointmentServices
  }
};

const mapActionsToProps = {
  onUpdateAppointmentServices: updateAppointmentServices,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Service));