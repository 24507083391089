import React from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Specialist extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleNextClick = () => {
    const data = {
      step: 'stepFour',
      stepActive: 4,
    }

    this.props.handleNext(data)
  }

  handlePrevClick = () => {
    const data = {
      step: 'stepOne',
      stepActive: 1,
    }

    this.props.handlePrev(data)
  }

  render() {
    const props = this.props
    const specialists = props.specialists
    const alias = props.match.params.alias

    return (
      <React.Fragment>
        <div className='specialist-container'>
          <div className="specialist__body">
            <p className="specialist__title">Specialist</p>
            {specialists.length ?
              specialists.map((specialist, index) =>
                <div
                  key={index}
                  id={specialist.specialistId}
                  data-index={specialist.specialistId}
                  className={props.selectedSpecialist
                    ? specialist.specialistId === props.selectedSpecialist.specialistId
                      ? "specialist__item specialist__item-active mb-3"
                      : "specialist__item specialist mb-3"
                    : "specialist__item specialist mb-3"}
                  onClick={props.handleSpecialistClick}
                >
                  <p
                    className="specialist__name"
                    data-index={specialist.specialistId}
                  >{specialist.specialistName}</p>
                </div>): <p className="specialist__noData">no available specialist</p>
            }

            <div className='d-flex justify-content-between pt-4'>
              <Link to={`/${alias}`}>
                <button className="btn btn-secondary back" onClick={this.handlePrevClick}>Back</button>
              </Link>

              <Link to={`/${alias}/date`}>
                <button
                  className={props.selectedSpecialist ?
                    "btn btn-primary next" :
                    "btn btn-primary next disabled"
                  }
                  onClick={this.handleNextClick}
                >Next</button>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Specialist);