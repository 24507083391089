import { UPDATE_COMPONENT_LOADING, UPDATE_PAGE_LOADING } from '../actions/page-loader-actions';

let initialState = {
  pageLoading: true,
  componentLoading: true
}

function pageLoaderReducer (state = initialState, {type, payload}) {
  switch (type) {
    case UPDATE_PAGE_LOADING:
      return {
        ...state,
        pageLoading: payload
      };
    case UPDATE_COMPONENT_LOADING:
      return {
        ...state,
        componentLoading: payload
      };
    default:
      return state;
  }
}

export default pageLoaderReducer;
