import React from 'react';
import { connect } from 'react-redux';
import { updatePage } from '../../actions/page-actions';
import { updateCustomerInformation } from '../../actions/customer-actions';
import { apiPostAppointment } from '../../actions/appointment-actions';

//import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg';
import { ReactComponent as Calendar } from '../../../assets/images/calendar.svg';
import LoaderTransparent from '../../layouts/loader-trans-layout';
import Swal from 'sweetalert2'
import InputMask from "react-input-mask";
import * as EmailValidator from 'email-validator';
import moment from 'moment';
import { scroller } from 'react-scroll';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import Select from 'react-select'

class AppointmentSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            day: [],
            month: [],
            daysOfMonth: 31,
            monthsOfYear: null,
            daysBootloader: false,
            currentLatitude: "",
            currentLongitude: ""
        };
    }

    handlePageChange() {
        this.props.onUpdatePage('form');
    }

    handleBookingSubmit(e) {
        e.preventDefault();
        const props = this.props;
        const state = this.state;
        const {name, mobile, email, birthDate, membership, vaccinated} = props.customerInfo;
        
        if (typeof state.month.length === 'undefined') {
            if (typeof state.day.length !== 'undefined') {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Birth day can not be empty when birth month is selected',
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true,
                    timerProgressBar: true, 
                });

                return false;
            }
        }

        if (!name || !mobile || !email) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please fill up customer information!',
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true, 
            });
        } else {
            const appointmentServices = props.appointmentServices;
            const merchantID = props.merchant.id;
            let validateEmail = email ? EmailValidator.validate(email) : true;
            let dataServices = [];
            let selectedSpecialist = [];
            let data = null;
            let requiredCounter = 0;

            appointmentServices.forEach((service) => {
                if (service.service.length === 0 || service.specialist.length === 0 || service.time.length === 0) {
                    requiredCounter += 1;
                }
            });

            if (requiredCounter > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Please fill up all service information!',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                });

                scroller.scrollTo('service-container', {
                    duration: 800,
                    delay: 100,
                    smooth: true,
                    offset: 100,
                });

                return false;
            }

            if (mobile.length < 12) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Please enter valid mobile number',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true, 
                });

                return false;
            }

            if (!validateEmail) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Please enter valid email address',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true, 
                });

                return false;
            }

            this.setState({
                loading: true
            });

            appointmentServices.forEach((data) => {
                let specialistID = data.specialist.value;
                let autoAssignSpecialist = props.merchant.merchant_business.auto_assign_specialist;

                // Check if Any Specialist (Auto Assign Queue)
                if (parseInt(specialistID) === 0 && autoAssignSpecialist === 1) {
                    const specialists = data.specialist.staffOptions;
                    let autoAssign = {
                        count: 0,
                        id: 0,
                    };

                    console.log(specialists);

                    specialists.forEach((sp, index) => {
                        let addCount = 0;
                        let timeAvailable = sp.availability;

                        if (timeAvailable.includes(data.time.value)) {
                            if (autoAssign.id === 0) {
                                autoAssign = {
                                    id: sp.id,
                                    count: sp.appointmentCount,
                                }
                            }

                            if (selectedSpecialist.length > 0) {
                                selectedSpecialist.forEach((ss) => {
                                    if (ss === sp.id) {
                                        addCount++
                                    }
                                });
                            }
    
                            if ((sp.appointmentCount + addCount) <= autoAssign.count) {
                                console.log('sp = ' + sp.id);
                                autoAssign = {
                                    count: sp.appointmentCount,
                                    id: sp.id
                                }
                            }
                        }
                    });

                    specialistID = autoAssign.id;

                    selectedSpecialist.push(specialistID);   
                } else {
                    selectedSpecialist.push(specialistID);
                }

                dataServices.push({
                    service: data.service.value,
                    specialist: specialistID,
                    time: data.time.value,
                    facility: 0
                });
            });
            
            // Check if specialistID is zero
            //const specialistCheck = (obj) => obj.specialist === 0;

            //if (dataServices.length > 0 && !dataServices.some(specialistCheck)) {
            if (dataServices.length > 0) {
                let customerNote = document.querySelector('#customerNote');
                data = {
                    source_id: 3,
                    origin: "EXTERNAL PAGE",
                    reservation_date: moment(props.appointmentDate, 'MMMM D YYYY').format('YYYY-MM-DD'),
                    guest_count: 1,
                    notes: customerNote ? customerNote.value : "",
                    customer_name: name,
                    customer_mobile_number: mobile,
                    customer_email: email,
                    customer_birth_date: birthDate,
                    customer_membership: (props.merchant.merchant_business.have_gift_rewards)? 1: 0,
                    customer_vaccinated: vaccinated,
                    info: [{
                        name: name,
                        details: dataServices
                    }],                    
                    currentLatitude: state.currentLatitude,
                    currentLongitude: state.currentLongitude
                };
                
                setTimeout(() => {
                    props.onApiPostAppointment(merchantID, data);
                }, 1000);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "There's an error on getting the data, please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    timerProgressBar: true, 
                });

                console.log(data);

                this.setState({
                    loading: false
                })
            }
        }
    }

    getGoelocation() {
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({
                currentLatitude: position.coords.latitude, 
                currentLongitude: position.coords.longitude
            })
        });
    }

    handleInputChange(elem) {
        let customer = this.props.customerInfo;

        customer[elem.target.id] = elem.target.value;
        
        this.props.onUpdateCustomerInformation(customer);
    }

    handleMonthChange(option) {
        this.setState({
            month: option,
            daysBootloader:true
        });

        this.daysOfMonth(option);

        Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Please select the day of your birth',
            showConfirmButton: false,
            timer: 3000,
            toast: true,
            timerProgressBar: true, 
        });
    }

    handleDayChange(option) {
        const state = this.state;

        this.setState({day: option});

        let customer = this.props.customerInfo;
        const month = state.month.label;
        const day = option.value;
        const year = moment().format('YYYY');

        let birthDate = month + " " + day + ", " + year;
        customer['birthDate'] = birthDate;
        
        this.props.onUpdateCustomerInformation(customer);
    }

    handleCheckboxChange(elem) {
        let customer = this.props.customerInfo;

        customer[elem.target.id] = elem.target.checked;
        
        this.props.onUpdateCustomerInformation(customer);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.monthsOfYear) {
            let merchant = this.props.merchant.merchant_business;
            let customer = this.props.customerInfo;
            let monthsCollection = [
                { value: 1, label: 'January' },
                { value: 2, label: 'February' },
                { value: 3, label: 'March' },
                { value: 4, label: 'April' },
                { value: 5, label: 'May' },
                { value: 6, label: 'June' },
                { value: 7, label: 'July' },
                { value: 8, label: 'August' },
                { value: 9, label: 'September' },
                { value: 10, label: 'October' },
                { value: 11, label: 'November' },
                { value: 12, label: 'December' }
            ]
            
            this.setState({
                monthsOfYear: monthsCollection,
            })

            customer['membership'] = true;
            if (merchant.have_vaccinated_salons != 0) {
                customer['vaccinated'] = true;
            }
            
            this.props.onUpdateCustomerInformation(customer);
            this.getGoelocation();
        }

        if (this.props.appointmentDetails !== prevProps.appointmentDetails) {
            const details = this.props.appointmentDetails;
            
            this.setState({
                loading: false
            });

            if (details.flag === 'success') {
                this.props.onUpdatePage('final');
            } else {
                if (details.response.data.userMessage) {
                    Swal.fire({
                        icon: 'error',
                        title: "There's a problem on your details",
                        text: details.response.data.userMessage,
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true, 
                        onClose: () => {
                            this.props.onUpdatePage(`form-${moment().valueOf()}`);
                            scroller.scrollTo('service-container', {
                                duration: 800,
                                delay: 100,
                                smooth: true,
                                offset: 100,
                            });
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Application Error',
                        text: "There's an error while sending your booking details.",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onClose: () => {
                            this.props.onUpdatePage(`form-${moment().valueOf()}`);
                            scroller.scrollTo('service-container', {
                                duration: 800,
                                delay: 100,
                                smooth: true,
                                offset: 100,
                            });
                        }
                    })
                }
            }
        }
    }

    handlePickerPress(e) {
        e.preventDefault();

        return false;
    }

    daysOfMonth(option) {
        const month = option.value;
        let days = 31;
        let daysCollection = [];

        for (let i = 0; days > i; i++) {
            let newDay = i+1;
            switch(month) {
                case 2:
                    if (newDay <= 29) {
                        daysCollection.push({ 
                            value: newDay, 
                            label: newDay 
                        });
                    }
                break;

                case 4:
                case 6:
                case 9:
                case 11:
                    if (newDay <= 30) {
                        daysCollection.push({ 
                            value: newDay, 
                            label: newDay 
                        });
                    }
                break;

                default:
                    daysCollection.push({ 
                        value: newDay, 
                        label: newDay 
                    });
            }
        }

        this.setState({
            month: option,
            daysOfMonth: daysCollection,
            daysBootloader:false
        });
    }

    render() {
        const noSelectedMonth = () => {
            const inputValue = "Please select month";

            return inputValue;
        }

        let props = this.props;
        let state= this.state;
        let services = [];
        let total = 0;
        let priceHTML = "";
        // let membership = "";

        if (props.appointmentServices) {
            props.appointmentServices.forEach((value, index) => {
                total += value.service.price ? parseFloat(value.service.price) : 0;
                let price = "";

                if (props.merchant.show_service_price) {
                    price = <div className={'summary__services__item__info'}>{value.service.price ? `$${value.service.price}` : ''}</div>;
                }

                services.push(
                    <div className={'summary__services__item'} key={index}>
                        <div className={'summary__services__item__service'}>{value.service.label ? value.service.label : ''}</div>
                        <div className={'summary__services__item__info'}>{value.specialist.label ? value.specialist.label : ''}</div>
                        <div className={'summary__services__item__info'}>
                            {value.time.label ? `${moment(value.time.label, 'h:mm A').format('h:mma')}` : ''}
                            { props.merchant.show_service_end_duration 
                                ? `- ${moment(value.time.label, 'h:mm A').add(value.service.duration, 'm').format('h:mma')}` : ''}
                        </div>
                        {price}
                    </div>
                );
            });
        } else {
            services = "";
        }

        if (props.merchant.show_service_price) {
            priceHTML = <div className={'appointment__summary__footer text--center'}>
                <h6 className={'appointment__summary__footer__title'}>subtotal</h6>
                <h3 className={'appointment__summary__footer__total'}>${total.toFixed(2)}</h3>

                <div className={'appointment__summary__footer__disclaimer'}>
                    <strong>DISCLAIMER</strong>
                    <p>Tax, tip and other possible charges are <strong>not yet included.</strong></p>
                    <p>Prices are subject to change without notice.</p>
                </div>
            </div>;
        }

        // if (props.merchant.merchant_business.have_gift_rewards) {
        //     membership = 
        //     <label className="custom-checkbox">
        //         Register as Gift and Rewards Member
        //         <input 
        //             name="membership"
        //             type="checkbox"
        //             id="membership"
        //             onChange={e => this.handleCheckboxChange(e)} 
        //             checked={props.customerInfo.membership ? props.customerInfo.membership  : ""} 
        //         />
        //         <span className="checkmark"></span>
        //     </label>
        // }
        
        return(
            <React.Fragment>
                <div id="summary" className={'flex flex--row flex--jc-sbetween'}>
                    <div className={'flex__col--12'}>
                        <div className={'appointment__form'}>
                            {/* <button className={'button button--link'} onClick={this.handlePageChange.bind(this)}>
                                <Arrow className={'button__icon'}/> back to service selection
                            </button> */}

                            <h4 className={'appointment__form__title'}>customer information</h4>

                            <h6 className={'appointment__form__note'}>* All fields are required.</h6>

                            <div className={'form__group'}>
                                <input type="text" className={'form__input'} id="name" placeholder={'enter name'} maxLength={60}
                                    onChange={e => this.handleInputChange(e)} value={props.customerInfo.name ? props.customerInfo.name  : ""}/>
                            </div>

                            <div className={'form__group'}>
                                <InputMask className={'form__input'} id="mobile" placeholder={'enter mobile'} 
                                    onChange={e => this.handleInputChange(e)} value={props.customerInfo.mobile ? props.customerInfo.mobile  : ""}
                                    mask="999-999-9999" maskChar={null}/>
                            </div>

                            <div className={'form__group'}>
                                <input type="text" className={'form__input'} id="email" placeholder={'enter email'} 
                                    onChange={e => this.handleInputChange(e)} value={props.customerInfo.email ? props.customerInfo.email  : ""}/>
                            </div>

                            <div className={'form__group'}>
                                <textarea className={'form__input'}
                                          style={{ height: "200px", lineHeight: "2rem", paddingTop: "1rem" }}
                                          id={"customerNote"}
                                          maxLength={499}
                                          placeholder={'enter note'}></textarea>
                            </div>

                            <h6 className={'appointment__form__note'}>* This fields are optional.</h6>

                            {/*<div className={'form__group birth_date_block'}>*/}
                            {/*    <DayPickerInput*/}
                            {/*        onDayChange={e => this.handleDayChange(e)}*/}
                            {/*        formatDate={formatDate}*/}
                            {/*        parseDate={parseDate}*/}
                            {/*        format={'MMMM D'}*/}
                            {/*        placeholder={'Select Birth Date'}*/}
                            {/*        inputProps={{*/}
                            {/*            className: 'form__input',*/}
                            {/*            id: 'date-picker',*/}
                            {/*            onKeyDown: (e => this.handlePickerPress(e)),*/}
                            {/*            readOnly: 'readonly'*/}
                            {/*        }}*/}
                            {/*        dayPickerProps={{*/}
                            {/*            toMonth: new Date()*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className={'service__row flex flex--row flex--ai-center'}>
                                <div className={'flex__col'}>
                                    <div className={'flex flex--row'}>
                                        <React.Fragment>
                                            <div className={'flex__col--6'}>
                                                <Select
                                                    options={state.monthsOfYear}
                                                    placeholder={'select birth month'}
                                                    isLoading={false}
                                                    isSearchable={false}
                                                    onChange={this.handleMonthChange.bind(this)}
                                                    value={state.month}
                                                    className={'form__picker'}
                                                />
                                            </div>

                                            <div className={'flex__col--6'}>
                                                <Select
                                                    options={state.daysOfMonth}
                                                    placeholder={'select birth day'}
                                                    isLoading={state.daysBootloader}
                                                    isSearchable={false}
                                                    onChange={this.handleDayChange.bind(this)}
                                                    value={state.day}
                                                    className={'form__picker'}
                                                    noOptionsMessage={noSelectedMonth}
                                                />
                                            </div>
                                            <div className={'flex__col--12'}>
                                                {
                                                    // membership
                                                }
                                            </div>
                                            {props.merchant.merchant_business.have_vaccinated_salons ?
                                                <div className={'flex__col--12'}>
                                                    <label className="custom-checkbox">
                                                        In compliance with covid-19 health protocol. I assure that I am fully vaccinated with vaccination proof to provide.
                                                        <input 
                                                            name="vaccinated"
                                                            type="checkbox"
                                                            id="vaccinated"
                                                            onChange={e => this.handleCheckboxChange(e)}
                                                            checked={props.customerInfo.vaccinated ? props.customerInfo.vaccinated  : ""}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            :'' }
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={'flex__col--12'}>
                        <div className={'appointment__summary'}>
                            <div className={'appointment__summary__header'}>
                                <h5 className={'appointment__summary__header__title'}>summary</h5>
                            </div>

                            <div className={'appointment__summary__body summary'}>
                                <div className={'summary__date'}>
                                    <Calendar /> {props.appointmentDate}
                                </div>

                                <div className={'summary__serivices'}>
                                    {services}
                                </div>

                                {priceHTML}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'text--center'}>
                    <button className={'button button--bg button--bg--success'} onClick={this.handleBookingSubmit.bind(this)}>Submit</button>

                    <div className={'user-acceptance'}>
                        <p>By clicking "BOOK APPOINTMENT", I agree to the Privacy Policy and Terms of Use.</p>
                        <p>This will serve as my electronic signature to allow GO3Reservation to use my information.</p>
                        <p>I authorize GO3Reservation to send SMS, Email notificaiton or pre-recorded messages to the information provided.</p>
                    </div>
                    
                </div>

                <LoaderTransparent loading={state.loading} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentDate: state.appointmentDate,
        appointmentServices: state.appointmentServices,
        customerInfo: state.customerInformation,
        appointmentDetails: state.appointmentDetails,
    }
};

const mapActionsToProps = {
    onUpdatePage: updatePage,
    onUpdateCustomerInformation: updateCustomerInformation,
    onApiPostAppointment: apiPostAppointment
};

export default connect(mapStateToProps, mapActionsToProps)(AppointmentSummary);