import axios from 'axios';
import Swal from "sweetalert2";
import * as Config from "../../utils/config";
import {updatePage} from "./page-actions";
import {updatePageLoading} from "./page-loader-actions";

export const UPDATE_DATE = 'appointmentDate:updateAppointmentDate';
export const UPDATE_SERVICES = 'appointmentServices:updateAppointmentServices';
export const UPDATE_SERVICE_SPECIALIST = 'appointmentServices:updateAppointmentServiceSpecialist';
export const GET_DETAILS = 'appointmentDetails:getAppointmentDetails';

export function updateAppointmentDate(day) {
    return {
        type: UPDATE_DATE,
        payload: {
            appointmentDate: day
        }
    }
}

export function updateAppointmentServices(serviceData) {
    return {
        type: UPDATE_SERVICES,
        payload: {
            appointmentServices: serviceData
        }
    }
}

export function updateAppointmentServiceSpecialist(specialistData) {
    return {
        type: UPDATE_SERVICE_SPECIALIST,
        payload: specialistData
    }
}

export function getAppointmentDetails(details) {
    return {
        type: GET_DETAILS,
        payload: {
            appointmentDetails: details
        }
    }
}

export function apiPostAppointment(merchantID, data) {
    return dispatch => {
        dispatch(updatePageLoading(true))

        const appointmentData = data;
        axios({
            method: 'post',
            url: `${Config.API_URL}/merchants/${merchantID}/reservations`,
            headers: {
                'Authorization': Config.TOKEN,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: data
        })
            .then((response) => {
                const data = response.data
                if (appointmentData.customer_membership) {
                    const costumer = (typeof data.merchant_customer !== 'undefined') ?
                        data.merchant_customer.is_gift_rewards_member :
                        data.internalMessage.merchant_customer.is_gift_rewards_member;

                    if (costumer != 1) {
                        const customerId = {
                            "enroll_immediate": 1,
                            "customer_ids": [
                                data.internalMessage.customer_id
                            ]
                        }

                        axios({
                            method: 'post',
                            url: `${Config.API_URL}/merchants/${merchantID}/gift-rewards/members`,
                            headers: {
                                'Authorization': Config.TOKEN,
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            data: customerId
                        })
                            .then((response) => {
                                console.log(response);
                            })
                            .catch((error) => {
                                console.log(error)
                                dispatch(getAppointmentDetails(error));
                            })
                    }
                }

                dispatch(getAppointmentDetails(data));
                dispatch(updatePageLoading(false))
                dispatch(updatePage("summary"))
            })
            .catch((error) => {
                console.log(error);

                const statusErrorResponse = [404, 422];
                if (statusErrorResponse.includes(error.response.status)) {
                    let messaging = error.response.data
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: messaging.internalMessage,
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        timerProgressBar: true,
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: `Error booking at this time. Please try again later.`,
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        timerProgressBar: true,
                    })
                }

                dispatch(getAppointmentDetails(error));
                dispatch(updatePageLoading(false))
            })
    }
}