export const UPDATE_INFO = 'customerInformation:updateCustomerInformation';

export function updateCustomerInformation(customer) {
  return {
    type: UPDATE_INFO,
    payload: {
      customerInformation: customer
    }
  }
}
