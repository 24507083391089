import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import _ from 'lodash'

import Service from './step/Service';
import Specialist from './step/Specialist';
import CustomerCount from './step/CustomerCount';
import CustomerInfo from './step/CustomerInfo';
import DateTime from './step/DateTime';

import {updateAppointmentServices, updateAppointmentDate} from '../actions/appointment-actions';
import {
    apiRequestAvailableSpecialists,
    apiRequestSpecialistAvailability
} from '../actions/available-specialist-actions';
import {updateCustomerInformation} from '../actions/customer-actions';
import Loader from "../layouts/loader";
import {Route, Switch, withRouter} from "react-router";

class AppointmentDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: "stepOne",
            stepActive: 1,
            tabActive: 'tab0',
            customerCount: 1,
            windowWidth: window.innerWidth,

            merchantSpecialists: [],

            appointmentServices: [],
            selectedService: null,
            serviceCategoryTitle: "",
            serviceCategories: [],

            appointmentSpecialists: [],
            selectedSpecialist: null,

            date: null,
            dateSlides: [],
            currentSlide: 0,
            selectedDate: null,
            showCalendar: false,

            time: null,
            selectedTime: null,
            timeCollection: [],

            appointmentStartDate: moment().format('YYYY-MM-DD'),
            scheduleLimitation: 60,
            daysBeforeBooking: 0,

            alias: '',
            disabledDates: []
        }

        this.handleNext = this.handleNext.bind(this)
        this.handlePrev = this.handlePrev.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.addCustomerCount = this.addCustomerCount.bind(this)
        this.handleServiceClick = this.handleServiceClick.bind(this)
        this.subtractCustomerCount = this.subtractCustomerCount.bind(this)

        this.handleCategory = this.handleCategory.bind(this)
        this.handleDayChange = this.handleDayChange.bind(this)
        this.handleTimeClick = this.handleTimeClick.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSpecialistClick = this.handleSpecialistClick.bind(this)
        this.handleDateSliderClick = this.handleDateSliderClick.bind(this)
    }

    componentDidUpdate(prevProps) {
        const props = this.props;
        const merchant = props.merchant;

        let categoryTitle = "";
        if (prevProps.merchant !== merchant && merchant) {
            const businessSettings = merchant.merchant_business

            let appointmentStartDate = moment().format('YYYY-MM-DD')
            let scheduleLimitation = 60
            let daysBeforeBooking = 0

            if (businessSettings) {
                if (businessSettings.online_appointment_start) {
                    let currentDate = moment()
                    let apptDate = moment(businessSettings.online_appointment_start)

                    if (currentDate.isSameOrAfter(apptDate)) {
                        appointmentStartDate = moment().format('YYYY-MM-DD')
                    } else {
                        appointmentStartDate = moment(businessSettings.online_appointment_start).format('YYYY-MM-DD')
                    }
                }

                if (businessSettings.schedule_limitation && parseInt(businessSettings.schedule_limitation)) {
                    scheduleLimitation = parseInt(businessSettings.schedule_limitation)
                    scheduleLimitation = scheduleLimitation <= 0 ? 60 : scheduleLimitation
                }

                if (businessSettings.days_before_booking) {
                    daysBeforeBooking = parseInt(businessSettings.days_before_booking)
                }
            }

            // Update appointmentStartDate
            // apply days befor booking
            if (daysBeforeBooking && daysBeforeBooking > 0) {
                appointmentStartDate = moment(appointmentStartDate, 'YYYY-MM-DD').add(daysBeforeBooking, 'days').format('YYYY-MM-DD')
            }

            const merchantSpecialists = merchant.specialists.filter(specialist => specialist.status === 1)

            // Create listing of all services
            let merchantServices = []
            let validCategories = []
            merchant.service_categories
                .filter(category => category.status === 1)
                .forEach((category, index) => {
                    const activeServices = category.merchant_services.filter(s => s.status === 1)

                    // Filter service that has specialists
                    activeServices.map(service => {
                        let serviceWithSpecialists = []
                        merchantSpecialists.forEach((specialist) => {
                            const hasSpecialist = specialist.services.filter(s => s.id === service.id)

                            if (hasSpecialist.length) {
                                serviceWithSpecialists.push(specialist)
                            }
                        })

                        service.specialists = serviceWithSpecialists
                    })

                    const serviceWithSpecialists = activeServices.filter(s => s.specialists.length)

                    if (serviceWithSpecialists.length) {
                        category.merchant_services = serviceWithSpecialists
                        validCategories.push(category)
                    }

                    const sortedServices = _.sortBy(serviceWithSpecialists, 'sort', 'asc')

                    merchantServices.push(...sortedServices)
                })

            console.log('validCategories', validCategories)
            const merchantCategories = _.sortBy(validCategories, 'sort', 'asc')

            // Check holiday entries
            let disabledDates = []
            const holidayEntries = merchant.holiday_entries

            if (holidayEntries.length) {
                holidayEntries.forEach(holiday => {
                    let startDate = moment(holiday.holiday_date, 'YYYY-MM-DD')
                    let endDate = moment(holiday.holiday_date_to, 'YYYY-MM-DD')
                    let currentDate = moment()
                    if (parseInt(holiday.has_hours) === 0) {
                        if (startDate.isSame(endDate)) {
                            disabledDates.push(startDate.format('YYYY-MM-DD'))
                        } else if (currentDate.isBetween(startDate, endDate) || startDate.isAfter(currentDate)) {
                            while (startDate.isSameOrBefore(endDate)) {
                                disabledDates.push(startDate.format('YYYY-MM-DD'))
                                startDate.add(1, 'day')
                            }
                        }
                    }
                })
            }

            const dateSlides = this.dateSlides(appointmentStartDate, scheduleLimitation, disabledDates)
            window.addEventListener('resize', this.handleResize.bind(this))

            let appointmentServices = this.props.appointmentServices;
            let selectedSpecialist = {
                availability: this.timeCollection(),
                specialistId: 0,
                specialistName: "Any Employee"
            }

            appointmentServices[0].specialist = selectedSpecialist
            this.props.onUpdateAppointmentServices(appointmentServices);

            this.setState({
                serviceCategories: merchantCategories,
                serviceCategoryTitle: categoryTitle,
                appointmentServices: merchantServices,
                merchantSpecialists: merchantSpecialists,
                selectedSpecialist: selectedSpecialist,

                dateSlides: dateSlides,

                date: dateSlides[0],
                selectedDate: dateSlides[0],

                appointmentStartDate: appointmentStartDate,
                scheduleLimitation: scheduleLimitation,
                daysBeforeBooking: daysBeforeBooking,

                alias: merchant.merchant_business.alias,
                disabledDates: disabledDates
            })
        }
    }

    handleResize() {
        this.setState({
            windowWidth: window.innerWidth
        })
    };

    dateSlides(appointmentStartDate, scheduleLimitation, disabledDates) {
        const days = []
        let dateStart = moment(appointmentStartDate, 'YYYY-MM-DD')
        let dateEnd = moment(appointmentStartDate, 'YYYY-MM-DD').add(scheduleLimitation, 'days')

        console.log('from dateSlides, appointment starts at ' + dateStart.format('YYYY-MM-DD'))
        console.log(`this is the scheduleLimitation ${scheduleLimitation} with the end of ${dateEnd.format("YYYY-MM-DD")}`)

        while (dateEnd.diff(dateStart, 'days') >= 0 && days.length < scheduleLimitation) {
            if (!disabledDates.includes(dateStart.format('YYYY-MM-DD'))) {
                days.push(dateStart.format('YYYY-MM-DD'))
            }
            dateStart.add(1, 'days')
        }

        return days
    }

    timeCollection() {
        let strTime = moment().startOf('day');

        const interval = 15; //minutes
        const numberOfTimes = (24 * 60) / interval;

        const timeCollection = [];
        for (let i = 0; numberOfTimes > i; i++) {
            timeCollection.push(strTime.format('HH:mm'));
            strTime = moment(strTime).add(interval, 'minutes');
        }

        return timeCollection;
    }

    handleCustomerCountChange(e) {
        let appointmentServices = this.props.appointmentServices;
        appointmentServices[e.target.id] = e.target.value;

        this.setState({customerCount: e.target.value})
    }

    handleInputChange(e) {
        let customerInformation = this.props.customerInformation;
        customerInformation[e.target.name] = e.target.value;

        this.props.onUpdateCustomerInformation(customerInformation);
    }

    handleDayChange(day) {
        let customerInformation = this.props.customerInformation;
        customerInformation['birthDate'] = day ? moment.utc(day).format('MMMM D YYYY') : '';

        this.props.onUpdateCustomerInformation(customerInformation);
    }

    handleCategory(e) {
        const categoryId = e.target.dataset.index
        const categories = this.state.serviceCategories

        if (categories) {
            categories.forEach((category, index) => {
                if (category.id === parseInt(categoryId)) {
                    this.setState({
                        serviceCategoryTitle: category.title,
                        appointmentServices: category.merchant_services
                    })
                }
            })
        }

        this.setState({
            tabActive: e.target.id
        });
    }

    handleServiceClick(e) {
        const serviceList = this.state.appointmentServices
        const dataIndex = e.target.dataset.index

        let option = ""
        serviceList.forEach((service, index) => {
            if (parseInt(dataIndex) === service.id) {
                option = service
            }
        })

        let cleared = 0;
        let selectedService = this.state.selectedService
        if (selectedService) {
            if (parseInt(dataIndex) === selectedService.id) {
                cleared = 1
                this.setState({selectedService: null})
            }
        }

        if (cleared === 0) {
            // Load available specialist by service
            let availableSpecialists = this.availableSpecialist(option)
            let services = this.props.appointmentServices

            /**
             * Update service + preselect specialist + date
             */
            let preselectSpecialist = null
            if (availableSpecialists.length > 0) {
                preselectSpecialist = availableSpecialists[0]
            } else {
                preselectSpecialist = {
                    availability: this.timeCollection(),
                    specialistId: 0,
                    specialistName: "Any Employee"
                }
                availableSpecialists = [preselectSpecialist]
            }

            services[0].service = option
            services[0].specialist = preselectSpecialist
            services[0].date = this.state.selectedDate // setup default date

            this.setState({
                selectedService: option,
                selectedSpecialist: preselectSpecialist,
                appointmentSpecialists: availableSpecialists
            });

            // Update store appointment service data
            this.props.onUpdateAppointmentServices(services);
        }
    }

    handleSpecialistClick(e) {
        const specialists = this.state.appointmentSpecialists
        const specialistId = e.target.dataset.index

        let option = specialists.filter(spec => spec.specialistId === parseInt(specialistId))[0]

        let cleared = 0;
        let selectedSpecialist = this.state.selectedSpecialist
        if (selectedSpecialist) {
            if (parseInt(specialistId) === selectedSpecialist.specialistId) {
                cleared = 1
                this.setState({selectedSpecialist: null})
            }
        }

        if (cleared === 0) {
            let services = this.props.appointmentServices;
            services[0].specialist = option;

            this.setState({selectedSpecialist: option});
            this.props.onUpdateAppointmentServices(services);
        }

        const merchantId = this.props.merchant.id;
        const serviceId = this.state.selectedService.id
        const serviceDuration = this.state.selectedService.estimated_duration
        const appointmentDate = this.state.selectedDate

        if (parseInt(specialistId) === 0) {
            this.props.requestAvailableSpecialists(merchantId, serviceId, appointmentDate, serviceDuration, 0, true)
        } else if (specialistId && parseInt(specialistId) !== 0) {
            this.props.requestSpecialistAvailability(merchantId, specialistId, serviceId, appointmentDate, serviceDuration, false)
        }
    }

    slideGroup(currentSlide) {
        const slidesVisible = this.state.windowWidth < 779 ? 4 : 7;
        const dateSliderLimit = 61;

        let slide;
        const start = dateSliderLimit - slidesVisible
        if (start <= currentSlide || currentSlide >= dateSliderLimit) {
            slide = start
        } else {
            slide = currentSlide
        }

        this.setState({
            currentSlide: slide
        });
    }

    handleDateSliderClick(e) {
        if (this.state.isUserMouseMoving) {
            e.preventDefault();
            return;
        }

        const dateSlides = this.state.dateSlides
        let services = this.props.appointmentServices;

        let dataIndex = "";
        if (typeof e.target === 'undefined') {
            dataIndex = moment(e).format('YYYY-MM-DD');
        } else {
            dataIndex = (!e.target.dataset.index) ? e.target.children[0].dataset.index : e.target.dataset.index;
        }

        let option = ""
        dateSlides.forEach((date, index) => {
            if (dataIndex === date) {
                option = date

                this.slideGroup(index);
            }
        })


        let cleared = 0;
        let selectedDate = this.state.selectedDate
        if (selectedDate) {
            if (dataIndex === selectedDate) {
                cleared = 1
                services[0].date = null;
                this.setState({selectedDate: null})
            }
        }

        if (cleared === 0) {
            services[0].date = option;
            this.setState({selectedDate: option});
        }

        // Update selected date option on appointmentServices
        this.props.onUpdateAppointmentServices(services);

        const merchantId = this.props.merchant.id;
        const serviceId = this.state.selectedService.id
        const serviceDuration = this.state.selectedService.estimated_duration
        const selectedSpecialistId = this.state.selectedSpecialist.specialistId
        const appointmentDate = moment(option).format('YYYY-MM-DD')

        // Load all available specialist
        if (selectedSpecialistId === 0) {
            this.props.requestAvailableSpecialists(merchantId, serviceId, appointmentDate, serviceDuration, 0, true)
        } else {
            this.props.requestSpecialistAvailability(merchantId, selectedSpecialistId, serviceId, appointmentDate, serviceDuration, true)
        }
    }

    handleTimeClick(e) {
        const targetTime = e.target.dataset.index
        let services = this.props.appointmentServices;

        let cleared = 0;
        let selectedTime = this.state.selectedTime
        if (selectedTime) {
            if (targetTime === selectedTime) {
                cleared = 1
                services[0].time = null;
                this.setState({selectedTime: null})
            }
        }

        if (cleared === 0) {
            services[0].time = targetTime;
            this.setState({selectedTime: targetTime});
        }

        this.props.onUpdateAppointmentServices(services);
    }

    handleNext(data) {
        if (data.stepActive === 2) {
            const merchantId = this.props.merchant.id;
            const serviceId = this.state.selectedService.id
            const serviceDuration = this.state.selectedService.estimated_duration
            const appointmentDate = this.state.selectedDate

            let selectedSpecialistId = 0;
            if (this.state.selectedSpecialist) {
                selectedSpecialistId = this.state.selectedSpecialist.specialistId
            }

            // Load all available specialist
            if (selectedSpecialistId === 0) {
                this.props.requestAvailableSpecialists(merchantId, serviceId, appointmentDate, serviceDuration, selectedSpecialistId, false)
            } else {
                this.props.requestSpecialistAvailability(merchantId, selectedSpecialistId, serviceId, appointmentDate, serviceDuration, false)
            }
        }

        this.setState(data)
    }

    handlePrev(data) {
        this.setState(data)
    }

    addCustomerCount() {
        let customerCount = this.state.customerCount;
        if (customerCount < 10) {
            customerCount += 1;
        }

        this.setState({customerCount})

        const service = this.props.appointmentServices;
        service[0].count = customerCount
        this.props.onUpdateAppointmentServices(service);
    }

    subtractCustomerCount() {
        let customerCount = this.state.customerCount;

        if (customerCount > 1) {
            customerCount -= 1;
        }

        this.setState({customerCount})

        const service = this.props.appointmentServices;

        service[0].count = customerCount
        this.props.onUpdateAppointmentServices(service);
    }

    handleDate(date) {
        this.setState({date})
    }

    availableSpecialist(selectedService) {
        let merchantSpecialists = this.state.merchantSpecialists
        let serviceSpecialists = [];

        let merchantTimezone = this.props.merchant.merchant_business.merchant_timezone.application_value
        let currentDay = moment().tz(merchantTimezone).format('dddd').toLowerCase()

        merchantSpecialists.forEach((specialist) => {
            const isSpecialist = specialist.services.filter(service => service.id === selectedService.id)
            if (isSpecialist.length) {
                // Check if specialist has schedule
                // if (specialist.contract[`operation_${currentDay}`]) {
                let specialistData = {
                    specialistName: specialist.first_name + ' ' + specialist.last_name,
                    specialistId: specialist.id,
                    availability: [],
                }

                serviceSpecialists.push(specialistData);
                // }
            }
        })

        // Sort by name
        if (serviceSpecialists.length) {
            serviceSpecialists = _.orderBy(serviceSpecialists, 'specialistName', 'asc')
        }

        if (serviceSpecialists.length && this.props.merchant.merchant_business.show_any_specialist) {
            let specialistData = {
                specialistName: 'Any specialist',
                specialistId: 0,
                availability: [],
            }

            serviceSpecialists.splice(0, 0, specialistData);
        }

        return serviceSpecialists
    }

    render() {
        const state = this.state

        let pathname = this.props.location.pathname
        let path = this.props.match.path

        return (
            <div className="container-fluid">
                <React.Fragment>
                    <div className="container steps-container pt-5">
                        <h5 className="app-title pb-3">Appointment Booking</h5>
                        <div className="steps row">
                            {state.step === 'stepOne' ?
                                <div className={'steps__progress steps__progress__active-half'}></div>
                                : <div className={'steps__progress steps__progress__active'}></div>
                            }

                            {state.step === 'stepTwo' ?
                                <div className={'steps__progress steps__progress__active-half'}></div>
                                : state.stepActive >= 2 ?
                                    <div className={'steps__progress steps__progress__active'}></div>
                                    : <div className={'steps__progress'}></div>
                            }

                            {state.step === 'stepThree' ?
                                <div className={'steps__progress steps__progress__active-half'}></div>
                                : state.stepActive >= 3 ?
                                    <div className={'steps__progress steps__progress__active'}></div>
                                    : <div className={'steps__progress'}></div>
                            }

                            {state.step === 'stepFour' ?
                                <div className={'steps__progress steps__progress__active-half'}></div>
                                : state.stepActive >= 4 ?
                                    <div className={'steps__progress steps__progress__active'}></div>
                                    : <div className={'steps__progress'}></div>
                            }

                            {state.step === 'stepFive' ?
                                <div className={'steps__progress steps__progress__active-half'}></div>
                                : state.stepActive >= 5 ?
                                    <div className={'steps__progress steps__progress__active'}></div>
                                    : <div className={'steps__progress'}></div>
                            }
                        </div>
                    </div>

                    {this.props.loader.pageLoading
                        ? <Loader/>
                        : <div className="container pt-3">
                            <Switch>
                                <Route exact={true} path={`${path}/customer`}>
                                    <CustomerInfo
                                        handlePrev={this.handlePrev}
                                        handleInputChange={this.handleInputChange}
                                        handleDayChange={this.handleDayChange}
                                    />
                                </Route>

                                <Route exact={true} path={`${path}/date`}>
                                    <DateTime
                                        date={state.date}
                                        dateSlides={state.dateSlides}
                                        currentSlide={state.currentSlide}
                                        showCalendar={state.showCalendar}
                                        selectedDate={state.selectedDate}
                                        handleDateClick={this.handleDateSliderClick}
                                        windowWidth={state.windowWidth}

                                        time={state.time}
                                        timeCollection={state.timeCollection}
                                        selectedTime={state.selectedTime}
                                        handleTimeClick={this.handleTimeClick}

                                        handleNext={this.handleNext}
                                        handlePrev={this.handlePrev}

                                        scheduleLimitation={state.scheduleLimitation}
                                        appointmentStartDate={state.appointmentStartDate}

                                        disabledDates={state.disabledDates}
                                    />
                                </Route>

                                <Route exact={true} path={`${path}/party-count`}>
                                    <CustomerCount
                                        handleNext={this.handleNext}
                                        handlePrev={this.handlePrev}
                                        addCustomerCount={this.addCustomerCount}
                                        subtractCustomerCount={this.subtractCustomerCount}
                                        customerCount={state.customerCount}
                                    />
                                </Route>

                                <Route exact={true} path={`${path}/specialists`}>
                                    <Specialist
                                        handleNext={this.handleNext}
                                        handlePrev={this.handlePrev}
                                        handleSpecialistClick={this.handleSpecialistClick}
                                        selectedSpecialist={state.selectedSpecialist}
                                        specialists={state.appointmentSpecialists}

                                        selectedService={this.state.selectedService}
                                    />
                                </Route>

                                <Route exact={true} path={pathname}>
                                    <Service
                                        handleNext={this.handleNext}
                                        handleCategory={this.handleCategory}
                                        handleServiceClick={this.handleServiceClick}
                                        services={this.state.appointmentServices}
                                        selectedService={this.state.selectedService}
                                        categoryTitle={this.state.serviceCategoryTitle}
                                        categories={this.state.serviceCategories}
                                        tabActive={this.state.tabActive}
                                        alias={this.state.alias}
                                    />
                                </Route>
                            </Switch>
                        </div>}

                </React.Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentDate: state.appointmentDate,
        appointmentServices: state.appointmentServices,
        customerInformation: state.customerInformation,
        loader: state.loader,
        availableSpecialists: state.availableSpecialists
    }
};

const mapActionsToProps = {
    onUpdateAppointmentServices: updateAppointmentServices,
    onUpdateCustomerInformation: updateCustomerInformation,
    onUpdateAppointmentDate: updateAppointmentDate,
    requestAvailableSpecialists: apiRequestAvailableSpecialists,
    requestSpecialistAvailability: apiRequestSpecialistAvailability
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(AppointmentDetails));